<template></template>

<script>
/* 所有班课目录 */
export default {
  data() {
    return {
      //Leetcode题目视频讲解
      leetCodeVideoClass: [
        {
          name: "LC 1 - 150 题",
          weeks: [
            {
              weekname: "LeetCode 1 - 50 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "1. Two Sum",
                "2. Add Two Numbers",
                "3. Longest Substring Without Repeating Characters",
                "4. Median of Two Sorted Arrays",
                "5. Longest Palindromic Substring",
                "6. ZigZag Conversion",
                "7. Reverse Integer",
                "8. String to Integer (atoi)",
                "9. Palindrome Number",
                "10. Regular Expression Matching",
                "11. Container With Most Water",
                "12. Integer to Roman",
                "13. Roman to Integer",
                "14. Longest Common Prefix",
                "15. 3Sum",
                "16. 3Sum Closest",
                "17. Letter Combinations of a Phone Number",
                "18. 4Sum",
                "19. Remove Nth Node From End of List",
                "20. Valid Parentheses",
                "21. Merge Two Sorted Lists",
                "22. Generate Parentheses",
                "23. Merge k Sorted Lists",
                "24. Swap Nodes in Pairs",
                "25. Reverse Nodes in k-Group",
                "26. Remove Duplicates from Sorted Array",
                "27. Remove Element",
                "28. Implement strStr()",
                "29. Divide Two Integers",
                "30. Substring with Concatenation of All Words",
                "31. Next Permutation",
                "32. Longest Valid Parentheses",
                "33. Search in Rotated Sorted Array",
                "34. Search for a Range",
                "35. Search Insert Position",
                "36. Valid Sudoku",
                "37. Sudoku Solver",
                "38. Count and Say",
                "39. Combination Sum",
                "40. Combination Sum II",
                "41. First Missing Positive",
                "42. Trapping Rain Water",
                "43. Multiply Strings",
                "44. Wildcard Matching",
                "45. Jump Game II",
                "46. Permutations",
                "47. Permutations II",
                "48. Rotate Image",
                "49. Group Anagrams",
                "50. Pow(x, n)",
              ],
            },
            {
              weekname: "LeetCode 51 - 100 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "51. N-Queens",
                "52. N-Queens II",
                "53. Maximum Subarray",
                "54. Spiral Matrix",
                "55. Jump Game",
                "56. Merge Intervals",
                "57. Insert Interval",
                "58. Length of Last Word",
                "59. Spiral Matrix II",
                "60. Permutation Sequence",
                "61. Rotate List",
                "62. Unique Paths",
                "63. Unique Paths II",
                "64. Minimum Path Sum",
                "65. Valid Number",
                "66. Plus One",
                "67. Add Binary",
                "68. Text Justification",
                "69. Sqrt(x)",
                "70. Climbing Stairs",
                "71. Simplify Path",
                "72. Edit Distance",
                "73. Set Matrix Zeroes",
                "74. Search a 2D Matrix",
                "75. Sort Colors",
                "76. Minimum Window Substring",
                "77. Combinations",
                "78. Subsets",
                "79. Word Search",
                "80. Remove Duplicates from Sorted Array II",
                "81. Search in Rotated Sorted Array II",
                "82. Remove Duplicates from Sorted List II",
                "83. Remove Duplicates from Sorted List",
                "84. Largest Rectangle in Histogram",
                "85. Maximal Rectangle",
                "86. Partition List",
                "87. Scramble String",
                "88. Merge Sorted Array",
                "89. Gray Code",
                "90. Subsets II",
                "91. Decode Ways",
                "92. Reverse Linked List II",
                "93. Restore IP Addresses",
                "94. Binary Tree Inorder Traversal",
                "95. Unique Binary Search Trees II",
                "96. Unique Binary Search Trees",
                "97. Interleaving String",
                "98. Validate Binary Search Tree",
                "99. Recover Binary Search Tree",
                "100. Same Tree",
              ],
            },
            {
              weekname: "LeetCode 101 - 150 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "101. Symmetric Tree",
                "102. Binary Tree Level Order Traversal",
                "103. Binary Tree Zigzag Level Order Traversal",
                "104. Maximum Depth of Binary Tree",
                "105. Construct Binary Tree from Preorder and Inorder Traversal",
                "106. Construct Binary Tree from Inorder and Postorder Traversal",
                "107. Binary Tree Level Order Traversal II",
                "108. Convert Sorted Array to Binary Search Tree",
                "109. Convert Sorted List to Binary Search Tree",
                "110. Balanced Binary Tree",
                "111. Minimum Depth of Binary Tree",
                "112. Path Sum",
                "113. Path Sum II",
                "114. Flatten Binary Tree to Linked List",
                "115. Distinct Subsequences",
                "116. Populating Next Right Pointers in Each Node",
                "117. Populating Next Right Pointers in Each Node II",
                "118. Pascal's Triangle",
                "119. Pascal's Triangle II",
                "120. Triangle",
                "121. Best Time to Buy and Sell Stock",
                "122. Best Time to Buy and Sell Stock II",
                "123. Best Time to Buy and Sell Stock III",
                "124. Binary Tree Maximum Path Sum",
                "125. Valid Palindrome",
                "126. Word Ladder II",
                "127. Word Ladder",
                "128. Longest Consecutive Sequence",
                "129. Sum Root to Leaf Numbers",
                "130. Surrounded Regions",
                "131. Palindrome Partitioning",
                "132. Palindrome Partitioning II",
                "133. Clone Graph",
                "134. Gas Station",
                "135. Candy",
                "136. Single Number",
                "137. Single Number II",
                "138. Copy List with Random Pointer",
                "139. Word Break",
                "140. Word Break II",
                "141. Linked List Cycle",
                "142. Linked List Cycle II",
                "143. Reorder List",
                "144. Binary Tree Preorder Traversal",
                "145. Binary Tree Postorder Traversal",
                "146. LRU Cache",
                "147. Insertion Sort List",
                "148. Sort List",
                "149. Max Points on a Line",
                "150. Evaluate Reverse Polish Notation",
              ],
            },
          ],
        },
        {
          name: "LC 151 - 300 题",
          weeks: [
            {
              weekname: "LeetCode 151 - 200 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "151. Reverse Words in a String",
                "152. Maximum Product Subarray",
                "153. Find Minimum in Rotated Sorted Array",
                "154. Find Minimum in Rotated Sorted Array II",
                "155. Min Stack",
                "156. Binary Tree Upside Down",
                "157. Read N Characters Given Read4",
                "158. Read N Characters Given Read4 II - Call multiple times",
                "159. Longest Substring with At Most Two Distinct Characters",
                "160. Intersection of Two Linked Lists",
                "161. One Edit Distance",
                "162. Find Peak Element",
                "163. Missing Ranges",
                "164. Maximum Gap",
                "165. Compare Version Numbers",
                "166. Fraction to Recurring Decimal",
                "167. Two Sum II - Input array is sorted",
                "168. Excel Sheet Column Title",
                "169. Majority Element",
                "170. Two Sum III - Data structure design",
                "171. Excel Sheet Column Number",
                "172. Factorial Trailing Zeroes",
                "173. Binary Search Tree Iterator",
                "174. Dungeon Game",
                "179. Largest Number",
                "186. Reverse Words in a String II",
                "187. Repeated DNA Sequences",
                "188. Best Time to Buy and Sell Stock IV",
                "189. Rotate Array",
                "190. Reverse Bits",
                "191. Number of 1 Bits",
                "198. House Robber",
                "199. Binary Tree Right Side View",
                "200. Number of Islands",
              ],
            },
            {
              weekname: "LeetCode 201 - 250 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "201. Bitwise AND of Numbers Range",
                "202. Happy Number",
                "203. Remove Linked List Elements",
                "204. Count Primes",
                "205. Isomorphic Strings",
                "206. Reverse Linked List",
                "207. Course Schedule",
                "208. Implement Trie (Prefix Tree)",
                "209. Minimum Size Subarray Sum",
                "210. Course Schedule II",
                "211. Add and Search Word - Data structure design",
                "212. Word Search II",
                "213. House Robber II",
                "214. Shortest Palindrome",
                "215. Kth Largest Element in an Array",
                "216. Combination Sum III",
                "217. Contains Duplicate",
                "218. The Skyline Problem",
                "219. Contains Duplicate II",
                "220. Contains Duplicate III",
                "221. Maximal Square",
                "222. Count Complete Tree Nodes",
                "223. Rectangle Area",
                "224. Basic Calculator",
                "225. Implement Stack using Queues",
                "226. Invert Binary Tree",
                "227. Basic Calculator II",
                "228. Summary Ranges",
                "229. Majority Element II",
                "230. Kth Smallest Element in a BST",
                "231. Power of Two",
                "232. Implement Queue using Stacks",
                "233. Number of Digit One",
                "234. Palindrome Linked List",
                "235. Lowest Common Ancestor of a Binary Search Tree",
                "236. Lowest Common Ancestor of a Binary Tree",
                "237. Delete Node in a Linked List",
                "238. Product of Array Except Self",
                "239. Sliding Window Maximum",
                "240. Search a 2D Matrix II",
                "241. Different Ways to Add Parentheses",
                "242. Valid Anagram",
                "243. Shortest Word Distance",
                "244. Shortest Word Distance II",
                "245. Shortest Word Distance III",
                "246. Strobogrammatic Number",
                "247. Strobogrammatic Number II",
                "248. Strobogrammatic Number III",
                "249. Group Shifted Strings",
                "250. Count Univalue Subtrees",
              ],
            },
            {
              weekname: "LeetCode 251 - 300 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "251. Flatten 2D Vector",
                "252. Meeting Rooms",
                "253. Meeting Rooms II",
                "254. Factor Combinations",
                "255. Verify Preorder Sequence in Binary Search Tree",
                "256. Paint House",
                "257. Binary Tree Paths",
                "258. Add Digits",
                "259. 3Sum Smaller",
                "260. Single Number III",
                "261. Graph Valid Tree",
                "263. Ugly Number",
                "264. Ugly Number II",
                "265. Paint House II",
                "266. Palindrome Permutation",
                "267. Palindrome Permutation II",
                "268. Missing Number",
                "269. Alien Dictionary",
                "270. Closest Binary Search Tree Value",
                "271. Encode and Decode Strings",
                "272. Closest Binary Search Tree Value II",
                "273. Integer to English Words",
                "274. H-Index",
                "275. H-Index II",
                "276. Paint Fence",
                "277. Find the Celebrity",
                "278. First Bad Version",
                "279. Perfect Squares",
                "280. Wiggle Sort",
                "281. Zigzag Iterator",
                "282. Expression Add Operators",
                "283. Move Zeroes",
                "284. Peeking Iterator",
                "285. Inorder Successor in BST",
                "286. Walls and Gates",
                "287. Find the Duplicate Number",
                "288. Unique Word Abbreviation",
                "289. Game of Life",
                "290. Word Pattern",
                "291. Word Pattern II",
                "292. Nim Game",
                "293. Flip Game",
                "294. Flip Game II",
                "295. Find Median from Data Stream",
                "296. Best Meeting Point",
                "297. Serialize and Deserialize Binary Tree",
                "298. Binary Tree Longest Consecutive Sequence",
                "299. Bulls and Cows",
                "300. Longest Increasing Subsequence",
              ],
            },
          ],
        },
        {
          name: "LC 301 - 450 题",
          weeks: [
            {
              weekname: "LeetCode 301 - 350 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "301. Remove Invalid Parentheses",
                "302. Smallest Rectangle Enclosing Black Pixels",
                "303. Range Sum Query - Immutable",
                "304. Range Sum Query 2D - Immutable",
                "305. Number of Islands II",
                "306. Additive Number",
                "307. Range Sum Query - Mutable",
                "308. Range Sum Query 2D - Mutable",
                "309. Best Time to Buy and Sell Stock with Cooldown",
                "310. Minimum Height Trees",
                "311. Sparse Matrix Multiplication",
                "312. Burst Balloons",
                "313. Super Ugly Number",
                "314. Binary Tree Vertical Order Traversal",
                "315. Count of Smaller Numbers After Self",
                "316. Remove Duplicate Letters",
                "317. Shortest Distance from All Buildings",
                "318. Maximum Product of Word Lengths",
                "319. Bulb Switcher",
                "320. Generalized Abbreviation",
                "321. Create Maximum Number",
                "322. Coin Change",
                "323. Number of Connected Components in an Undirected Graph",
                "324. Wiggle Sort II",
                "325. Maximum Size Subarray Sum Equals k",
                "326. Power of Three",
                "327. Count of Range Sum",
                "328. Odd Even Linked List",
                "329. Longest Increasing Path in a Matrix",
                "330. Patching Array",
                "331. Verify Preorder Serialization of a Binary Tree",
                "332. Reconstruct Itinerary",
                "333. Largest BST Subtree",
                "334. Increasing Triplet Subsequence",
                "335. Self Crossing",
                "336. Palindrome Pairs",
                "337. House Robber III",
                "338. Counting Bits",
                "339. Nested List Weight Sum",
                "340. Longest Substring with At Most K Distinct Characters",
                "341. Flatten Nested List Iterator",
                "342. Power of Four",
                "343. Integer Break",
                "344. Reverse String",
                "345. Reverse Vowels of a String",
                "346. Moving Average from Data Stream",
                "347. Top K Frequent Elements",
                "348. Design Tic-Tac-Toe",
                "349. Intersection of Two Arrays",
                "350. Intersection of Two Arrays II",
              ],
            },
            {
              weekname: "LeetCode 351 - 400 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "351. Android Unlock Patterns",
                "352. Data Stream as Disjoint Intervals",
                "353. Design Snake Game",
                "354. Russian Doll Envelopes",
                "355. Design Twitter",
                "356. Line Reflection",
                "357. Count Numbers with Unique Digits",
                "358. Rearrange String k Distance Apart",
                "359. Logger Rate Limiter",
                "360. Sort Transformed Array",
                "361. Bomb Enemy",
                "362. Design Hit Counter",
                "363. Max Sum of Rectangle No Larger Than K",
                "364. Nested List Weight Sum II",
                "365. Water and Jug Problem",
                "366. Find Leaves of Binary Tree",
                "367. Valid Perfect Square",
                "368. Largest Divisible Subset",
                "369. Plus One Linked List",
                "370. Range Addition",
                "371. Sum of Two Integers",
                "372. Super Pow",
                "373. Find K Pairs with Smallest Sums",
                "374. Guess Number Higher or Lower",
                "375. Guess Number Higher or Lower II",
                "376. Wiggle Subsequence",
                "377. Combination Sum IV",
                "378. Kth Smallest Element in a Sorted Matrix",
                "379. Design Phone Directory",
                "380. Insert Delete GetRandom O(1)",
                "381. Insert Delete GetRandom O(1) - Duplicates allowed",
                "382. Linked List Random Node",
                "383. Ransom Note",
                "384. Shuffle an Array",
                "385. Mini Parser",
                "386. Lexicographical Numbers",
                "387. First Unique Character in a String",
                "388. Longest Absolute File Path",
                "389. Find the Difference",
                "390. Elimination Game",
                "391. Perfect Rectangle",
                "392. Is Subsequence",
                "393. UTF-8 Validation",
                "394. Decode String",
                "395. Longest Substring with At Least K Repeating Characters",
                "396. Rotate Function",
                "397. Integer Replacement",
                "398. Random Pick Index",
                "399. Evaluate Division",
                "400. Nth Digit",
              ],
            },
            {
              weekname: "LeetCode 401 - 450 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "401. Binary Watch",
                "402. Remove K Digits",
                "403. Frog Jump",
                "404. Sum of Left Leaves",
                "405. Convert a Number to Hexadecimal",
                "406. Queue Reconstruction by Height",
                "407. Trapping Rain Water II",
                "408. Valid Word Abbreviation",
                "409. Longest Palindrome",
                "410. Split Array Largest Sum",
                "411. Minimum Unique Word Abbreviation",
                "412. Fizz Buzz",
                "413. Arithmetic Slices",
                "414. Third Maximum Number",
                "415. Add Strings",
                "416. Partition Equal Subset Sum",
                "417. Pacific Atlantic Water Flow",
                "418. Sentence Screen Fitting",
                "419. Battleships in a Board",
                "420. Strong Password Checker",
                "421. Maximum XOR of Two Numbers in an Array",
                "422. Valid Word Square",
                "423. Reconstruct Original Digits from English",
                "424. Longest Repeating Character Replacement",
                "425. Word Squares",
                "432. All O`one Data Structure",
                "434. Number of Segments in a String",
                "435. Non-overlapping Intervals",
                "436. Find Right Interval",
                "437. Path Sum III",
                "438. Find All Anagrams in a String",
                "439. Ternary Expression Parser",
                "440. K-th Smallest in Lexicographical Order",
                "441. Arranging Coins",
                "442. Find All Duplicates in an Array",
                "443. String Compression",
                "444. Sequence Reconstruction",
                "445. Add Two Numbers II",
                "446. Arithmetic Slices II - Subsequence",
                "447. Number of Boomerangs",
                "448. Find All Numbers Disappeared in an Array",
                "449. Serialize and Deserialize BST",
                "450. Delete Node in a BST",
              ],
            },
          ],
        },
        {
          name: "LC 451 - 600 题",
          weeks: [
            {
              weekname: "LeetCode 451 - 500 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "451. Sort Characters By Frequency",
                "452. Minimum Number of Arrows to Burst Balloons",
                "453. Minimum Moves to Equal Array Elements",
                "454. 4Sum II",
                "455. Assign Cookies",
                "456. 132 Pattern",
                "459. Repeated Substring Pattern",
                "460. LFU Cache",
                "461. Hamming Distance",
                "462. Minimum Moves to Equal Array Elements II",
                "463. Island Perimeter",
                "464. Can I Win",
                "465. Optimal Account Balancing",
                "466. Count The Repetitions",
                "467. Unique Substrings in Wraparound String",
                "468. Validate IP Address",
                "469. Convex Polygon",
                "471. Encode String with Shortest Length",
                "472. Concatenated Words",
                "473. Matchsticks to Square",
                "474. Ones and Zeroes",
                "475. Heaters",
                "476. Number Complement",
                "477. Total Hamming Distance",
                "479. Largest Palindrome Product",
                "480. Sliding Window Median",
                "481. Magical String",
                "482. License Key Formatting",
                "483. Smallest Good Base",
                "484. Find Permutation",
                "485. Max Consecutive Ones",
                "486. Predict the Winner",
                "487. Max Consecutive Ones II",
                "488. Zuma Game",
                "490. The Maze",
                "491. Increasing Subsequences",
                "492. Construct the Rectangle",
                "493. Reverse Pairs",
                "494. Target Sum",
                "495. Teemo Attacking",
                "496. Next Greater Element I",
                "498. Diagonal Traverse",
                "499. The Maze III",
                "500. Keyboard Row",
              ],
            },
            {
              weekname: "LeetCode 501 - 550 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "501. Find Mode in Binary Search Tree",
                "502. IPO",
                "503. Next Greater Element II",
                "504. Base 7",
                "505. The Maze II",
                "506. Relative Ranks",
                "507. Perfect Number",
                "508. Most Frequent Subtree Sum",
                "513. Find Bottom Left Tree Value",
                "514. Freedom Trail",
                "515. Find Largest Value in Each Tree Row",
                "516. Longest Palindromic Subsequence",
                "517. Super Washing Machines",
                "520. Detect Capital",
                "521. Longest Uncommon Subsequence I",
                "522. Longest Uncommon Subsequence II",
                "523. Continuous Subarray Sum",
                "524. Longest Word in Dictionary through Deleting",
                "525. Contiguous Array",
                "526. Beautiful Arrangement",
                "527. Word Abbreviation",
                "529. Minesweeper",
                "530. Minimum Absolute Difference in BST",
                "531. Lonely Pixel I",
                "532. K-diff Pairs in an Array",
                "533. Lonely Pixel II",
                "535. Encode and Decode TinyURL",
                "536. Construct Binary Tree from String",
                "537. Complex Number Multiplication",
                "538. Convert BST to Greater Tree",
                "539. Minimum Time Difference",
                "540. Single Element in a Sorted Array",
                "541. Reverse String II",
                "542. 01 Matrix",
                "543. Diameter of Binary Tree",
                "544. Output Contest Matches",
                "545. Boundary of Binary Tree",
                "546. Remove Boxes",
                "547. Friend Circles",
                "548. Split Array with Equal Sum",
                "549. Binary Tree Longest Consecutive Sequence II",
              ],
            },
            {
              weekname: "LeetCode 551 - 600 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "551. Student Attendance Record I",
                "552. Student Attendance Record II",
                "553. Optimal Division",
                "554. Brick Wall",
                "555. Split Concatenated Strings",
                "556. Next Greater Element III",
                "557. Reverse Words in a String III",
                "560. Subarray Sum Equals K",
                "561. Array Partition I",
                "562. Longest Line of Consecutive One in Matrix",
                "563. Binary Tree Tilt",
                "564. Find the Closest Palindrome",
                "565. Array Nesting",
                "566. Reshape the Matrix",
                "567. Permutation in String",
                "568. Maximum Vacation Days",
                "572. Subtree of Another Tree",
                "573. Squirrel Simulation",
                "575. Distribute Candies",
                "576. Out of Boundary Paths",
                "581. Shortest Unsorted Continuous Subarray",
                "582. Kill Process",
                "583. Delete Operation for Two Strings",
                "587. Erect the Fence",
                "588. Design In-Memory File System",
                "591. Tag Validator",
                "592. Fraction Addition and Subtraction",
                "593. Valid Square",
                "594. Longest Harmonious Subsequence",
                "598. Range Addition II",
                "599. Minimum Index Sum of Two Lists",
                "600. Non-negative Integers without Consecutive Ones",
              ],
            },
          ],
        },
        {
          name: "LC 601 - 750 题",
          weeks: [
            {
              weekname: "LeetCode 601 - 650 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "604. Design Compressed String Iterator",
                "605. Can Place Flowers",
                "606. Construct String from Binary Tree",
                "609. Find Duplicate File in System",
                "611. Valid Triangle Number",
                "616. Add Bold Tag in String",
                "617. Merge Two Binary Trees",
                "621. Task Scheduler",
                "622. Design Circular Queue",
                "623. Add One Row to Tree",
                "624. Maximum Distance in Arrays",
                "625. Minimum Factorization",
                "628. Maximum Product of Three Numbers",
                "629. K Inverse Pairs Array",
                "630. Course Schedule III",
                "631. Design Excel Sum Formula",
                "632. Smallest Range Covering Elements from K Lists",
                "633. Sum of Square Numbers",
                "634. Find the Derangement of An Array",
                "635. Design Log Storage System",
                "636. Exclusive Time of Functions",
                "637. Average of Levels in Binary Tree",
                "638. Shopping Offers",
                "639. Decode Ways II",
                "640. Solve the Equation",
                "641. Design Circular Deque",
                "642. Design Search Autocomplete System",
                "643. Maximum Average Subarray I",
                "644. Maximum Average Subarray II",
                "645. Set Mismatch",
                "646. Maximum Length of Pair Chain",
                "647. Palindromic Substrings",
                "648. Replace Words",
                "649. Dota2 Senate",
                "650. 2 Keys Keyboard",
              ],
            },
            {
              weekname: "LeetCode 651 - 700 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "651. 4 Keys Keyboard",
                "652. Find Duplicate Subtrees",
                "653. Two Sum IV - Input is a BST",
                "654. Maximum Binary Tree",
                "655. Print Binary Tree",
                "656. Coin Path",
                "657. Robot Return to Origin",
                "658. Find K Closest Elements",
                "659. Split Array into Consecutive Subsequences",
                "660. Remove 9",
                "661. Image Smoother",
                "662. Maximum Width of Binary Tree",
                "663. Equal Tree Partition",
                "664. Strange Printer",
                "665. Non-decreasing Array",
                "666. Path Sum IV",
                "667. Beautiful Arrangement II",
                "668. Kth Smallest Number in Multiplication Table",
                "669. Trim a Binary Search Tree",
                "670. Maximum Swap",
                "671. Second Minimum Node In a Binary Tree",
                "672. Bulb Switcher II",
                "673. Number of Longest Increasing Subsequence",
                "674. Longest Continuous Increasing Subsequence",
                "675. Cut Off Trees for Golf Event",
                "676. Implement Magic Dictionary",
                "677. Map Sum Pairs",
                "678. Valid Parenthesis String",
                "679. 24 Game",
                "680. Valid Palindrome II",
                "681. Next Closest Time",
                "682. Baseball Game",
                "683. K Empty Slots",
                "684. Redundant Connection",
                "685. Redundant Connection II",
                "686. Repeated String Match",
                "687. Longest Univalue Path",
                "688. Knight Probability in Chessboard",
                "689. Maximum Sum of 3 Non-Overlapping Subarrays",
                "690. Employee Importance",
                "691. Stickers to Spell Word",
                "692. Top K Frequent Words",
                "693. Binary Number with Alternating Bits",
                "694. Number of Distinct Islands",
                "695. Max Area of Island",
                "696. Count Binary Substrings",
                "697. Degree of an Array",
                "698. Partition to K Equal Sum Subsets",
                "699. Falling Squares",
                "700. Search in a Binary Search Tree",
              ],
            },
            {
              weekname: "LeetCode 701 - 750 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "701. Insert into a Binary Search Tree",
                "702. Search in a Sorted Array of Unknown Size",
                "703. Kth Largest Element in a Stream",
                "704. Binary Search",
                "705. Design HashSet",
                "706. Design HashMap",
                "707. Design Linked List",
                "708. Insert into a Sorted Circular Linked List",
                "709. To Lower Case",
                "710. Random Pick with Blacklist",
                "711. Number of Distinct Islands II",
                "712. Minimum ASCII Delete Sum for Two Strings",
                "713. Subarray Product Less Than K",
                "714. Best Time to Buy and Sell Stock with Transaction Fee",
                "715. Range Module",
                "716. Max Stack",
                "717. 1-bit and 2-bit Characters",
                "718. Maximum Length of Repeated Subarray",
                "719. Find K-th Smallest Pair Distance",
                "720. Longest Word in Dictionary",
                "721. Accounts Merge",
                "722. Remove Comments",
                "723. Candy Crush",
                "724. Find Pivot Index",
                "725. Split Linked List in Parts",
                "726. Number of Atoms",
                "727. Minimum Window Subsequence",
                "728. Self Dividing Numbers",
                "729. My Calendar I",
                "730. Count Different Palindromic Subsequences",
                "731. My Calendar II",
                "732. My Calendar III",
                "733. Flood Fill",
                "734. Sentence Similarity",
                "735. Asteroid Collision",
                "736. Parse Lisp Expression",
                "737. Sentence Similarity II",
                "738. Monotone Increasing Digits",
                "739. Daily Temperatures",
                "740. Delete and Earn",
                "741. Cherry Pickup",
                "742. Closest Leaf in a Binary Tree",
                "743. Network Delay Time",
                "744. Find Smallest Letter Greater Than Target",
                "745. Prefix and Suffix Search",
                "746. Min Cost Climbing Stairs",
                "747. Largest Number At Least Twice of Others",
                "748. Shortest Completing Word",
                "749. Contain Virus",
                "750. Number Of Corner Rectangles",
              ],
            },
          ],
        },
        {
          name: "LC 751 - 900 题",
          weeks: [
            {
              weekname: "LeetCode 751 - 800 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "751. IP to CIDR",
                "752. Open the Lock",
                "753. Cracking the Safe",
                "754. Reach a Number",
                "755. Pour Water",
                "756. Pyramid Transition Matrix",
                "757. Set Intersection Size At Least Two",
                "758. Bold Words in String",
                "759. Employee Free Time",
                "760. Find Anagram Mappings",
                "761. Special Binary String",
                "762. Prime Number of Set Bits in Binary Representation",
                "763. Partition Labels",
                "764. Largest Plus Sign",
                "765. Couples Holding Hands",
                "766. Toeplitz Matrix",
                "767. Reorganize String",
                "768. Max Chunks To Make Sorted II",
                "769. Max Chunks To Make Sorted",
                "770. Basic Calculator IV",
                "771. Jewels and Stones",
                "772. Basic Calculator III",
                "773. Sliding Puzzle",
                "774. Minimize Max Distance to Gas Station",
                "775. Global and Local Inversions",
                "776. Split BST",
                "777. Swap Adjacent in LR String",
                "778. Swim in Rising Water",
                "779. K-th Symbol in Grammar",
                "780. Reaching Points",
                "781. Rabbits in Forest",
                "782. Transform to Chessboard",
                "783. Minimum Distance Between BST Nodes",
                "784. Letter Case Permutation",
                "785. Is Graph Bipartite?",
                "786. K-th Smallest Prime Fraction",
                "787. Cheapest Flights Within K Stops",
                "788. Rotated Digits",
                "789. Escape The Ghosts",
                "790. Domino and Tromino Tiling",
                "791. Custom Sort String",
                "792. Number of Matching Subsequences",
                "793. Preimage Size of Factorial Zeroes Function",
                "794. Valid Tic-Tac-Toe State",
                "795. Number of Subarrays with Bounded Maximum",
                "796. Rotate String",
                "797. All Paths From Source to Target",
                "798. Smallest Rotation with Highest Score",
                "799. Champagne Tower",
                "800. Similar RGB Color",
              ],
            },
            {
              weekname: "LeetCode 801 - 850 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "801. Minimum Swaps To Make Sequences Increasing",
                "802. Find Eventual Safe States",
                "803. Bricks Falling When Hit",
                "804. Unique Morse Code Words",
                "805. Split Array With Same Average",
                "806. Number of Lines To Write String",
                "807. Max Increase to Keep City Skyline",
                "808. Soup Servings",
                "809. Expressive Words",
                "810. Chalkboard XOR Game",
                "811. Subdomain Visit Count",
                "812. Largest Triangle Area",
                "813. Largest Sum of Averages",
                "814. Binary Tree Pruning",
                "815. Bus Routes",
                "816. Ambiguous Coordinates",
                "817. Linked List Components",
                "818. Race Car",
                "819. Most Common Word",
                "820. Short Encoding of Words",
                "821. Shortest Distance to a Character",
                "822. Card Flipping Game",
                "823. Binary Trees With Factors",
                "824. Goat Latin",
                "825. Friends Of Appropriate Ages",
                "826. Most Profit Assigning Work",
                "827. Making A Large Island",
                "828. Count Unique Characters of All Substrings of a Given String",
                "829. Consecutive Numbers Sum",
                "830. Positions of Large Groups",
                "831. Masking Personal Information",
                "832. Flipping an Image",
                "833. Find And Replace in String",
                "834. Sum of Distances in Tree",
                "835. Image Overlap",
                "836. Rectangle Overlap",
                "837. New 21 Game",
                "838. Push Dominoes",
                "839. Similar String Groups",
                "840. Magic Squares In Grid",
                "841. Keys and Rooms",
                "842. Split Array into Fibonacci Sequence",
                "843. Guess the Word",
                "844. Backspace String Compare",
                "845. Longest Mountain in Array",
                "846. Hand of Straights",
                "847. Shortest Path Visiting All Nodes",
                "848. Shifting Letters",
                "849. Maximize Distance to Closest Person",
                "850. Rectangle Area II",
              ],
            },
            {
              weekname: "LeetCode 851 - 900 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "851. Loud and Rich",
                "852. Peak Index in a Mountain Array",
                "853. Car Fleet",
                "854. K-Similar Strings",
                "855. Exam Room",
                "856. Score of Parentheses",
                "857. Minimum Cost to Hire K Workers",
                "858. Mirror Reflection",
                "859. Buddy Strings",
                "860. Lemonade Change",
                "861. Score After Flipping Matrix",
                "862. Shortest Subarray with Sum at Least K",
                "863. All Nodes Distance K in Binary Tree",
                "864. Shortest Path to Get All Keys",
                "865. Smallest Subtree with all the Deepest Nodes",
                "866. Prime Palindrome",
                "867. Transpose Matrix",
                "868. Binary Gap",
                "869. Reordered Power of 2",
                "870. Advantage Shuffle",
                "871. Minimum Number of Refueling Stops",
                "872. Leaf-Similar Trees",
                "873. Length of Longest Fibonacci Subsequence",
                "874. Walking Robot Simulation",
                "875. Koko Eating Bananas",
                "876. Middle of the Linked List",
                "877. Stone Game",
                "878. Nth Magical Number",
                "879. Profitable Schemes",
                "880. Decoded String at Index",
                "881. Boats to Save People",
                "882. Reachable Nodes In Subdivided Graph",
                "883. Projection Area of 3D Shapes",
                "884. Uncommon Words from Two Sentences",
                "885. Spiral Matrix III",
                "886. Possible Bipartition",
                "887. Super Egg Drop",
                "888. Fair Candy Swap",
                "889. Construct Binary Tree from Preorder and Postorder Traversal",
                "890. Find and Replace Pattern",
                "891. Sum of Subsequence Widths",
                "892. Surface Area of 3D Shapes",
                "893. Groups of Special-Equivalent Strings",
                "894. All Possible Full Binary Trees",
                "895. Maximum Frequency Stack",
                "896. Monotonic Array",
                "897. Increasing Order Search Tree",
                "898. Bitwise ORs of Subarrays",
                "899. Orderly Queue",
                "900. RLE Iterator",
              ],
            },
          ],
        },
        {
          name: "LC 901 - 1000 题",
          weeks: [
            {
              weekname: "LeetCode 901 - 950 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "901. Online Stock Span",
                "902. Numbers At Most N Given Digit Set",
                "903. Valid Permutations for DI Sequence",
                "904. Fruit Into Baskets",
                "905. Sort Array By Parity",
                "906. Super Palindromes",
                "907. Sum of Subarray Minimums",
                "908. Smallest Range I",
                "909. Snakes and Ladders",
                "910. Smallest Range II",
                "911. Online Election",
                "912. Sort an Array",
                "913. Cat and Mouse",
                "914. X of a Kind in a Deck of Cards",
                "915. Partition Array into Disjoint Intervals",
                "916. Word Subsets",
                "917. Reverse Only Letters",
                "918. Maximum Sum Circular Subarray",
                "919. Complete Binary Tree Inserter",
                "920. Number of Music Playlists",
                "921. Minimum Add to Make Parentheses Valid",
                "922. Sort Array By Parity II",
                "923. 3Sum With Multiplicity",
                "924. Minimize Malware Spread",
                "925. Long Pressed Name",
                "926. Flip String to Monotone Increasing",
                "927. Three Equal Parts",
                "928. Minimize Malware Spread II",
                "929. Unique Email Addresses",
                "930. Binary Subarrays With Sum",
                "931. Minimum Falling Path Sum",
                "932. Beautiful Array",
                "933. Number of Recent Calls",
                "934. Shortest Bridge",
                "935. Knight Dialer",
                "936. Stamping The Sequence",
                "937. Reorder Data in Log Files",
                "938. Range Sum of BST",
                "939. Minimum Area Rectangle",
                "940. Distinct Subsequences II",
                "941. Valid Mountain Array",
                "942. DI String Match",
                "943. Find the Shortest Superstring",
                "944. Delete Columns to Make Sorted",
                "945. Minimum Increment to Make Array Unique",
                "946. Validate Stack Sequences",
                "947. Most Stones Removed with Same Row or Column",
                "948. Bag of Tokens",
                "949. Largest Time for Given Digits",
                "950. Reveal Cards In Increasing Order",
              ],
            },
            {
              weekname: "LeetCode 951 - 1000 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "951. Flip Equivalent Binary Trees",
                "952. Largest Component Size by Common Factor",
                "953. Verifying an Alien Dictionary",
                "954. Array of Doubled Pairs",
                "955. Delete Columns to Make Sorted II",
                "956. Tallest Billboard",
                "957. Prison Cells After N Days",
                "958. Check Completeness of a Binary Tree",
                "959. Regions Cut By Slashes",
                "960. Delete Columns to Make Sorted III",
                "961. N-Repeated Element in Size 2N Array",
                "962. Maximum Width Ramp",
                "963. Minimum Area Rectangle II",
                "964. Least Operators to Express Number",
                "965. Univalued Binary Tree",
                "966. Vowel Spellchecker",
                "967. Numbers With Same Consecutive Differences",
                "968. Binary Tree Cameras",
                "969. Pancake Sorting",
                "970. Powerful Integers",
                "971. Flip Binary Tree To Match Preorder Traversal",
                "972. Equal Rational Numbers",
                "973. K Closest Points to Origin",
                "974. Subarray Sums Divisible by K",
                "975. Odd Even Jump",
                "976. Largest Perimeter Triangle",
                "977. Squares of a Sorted Array",
                "978. Longest Turbulent Subarray",
                "979. Distribute Coins in Binary Tree",
                "980. Unique Paths III",
                "981. Time Based Key-Value Store",
                "982. Triples with Bitwise AND Equal To Zero",
                "983. Minimum Cost For Tickets",
                "984. String Without AAA or BBB",
                "985. Sum of Even Numbers After Queries",
                "986. Interval List Intersections",
                "987. Vertical Order Traversal of a Binary Tree",
                "988. Smallest String Starting From Leaf",
                "989. Add to Array-Form of Integer",
                "990. Satisfiability of Equality Equations",
                "991. Broken Calculator",
                "992. Subarrays with K Different Integers",
                "993. Cousins in Binary Tree",
                "994. Rotting Oranges",
                "995. Minimum Number of K Consecutive Bit Flips",
                "996. Number of Squareful Arrays",
                "997. Find the Town Judge",
                "998. Maximum Binary Tree II",
                "999. Available Captures for Rook",
                "1000. Minimum Cost to Merge Stones",
              ],
            },
          ],
        },
      ],
      //求职面试算法班
      algorithmFoundationClass: [
        {
          name: "第 1 课 ~ 第 4 课",
          weeks: [
            {
              weekname: "第 1 课 课程介绍",
              dis: "刷题常见的问题和必备基础",
              children: [
                "1、课程介绍-如何最快速的刷题",
                "2、课程介绍-刷题常见问题",
                "3、课程介绍-Java 编程风格",
              ],
            },
            {
              weekname: "第 2 课 算法，时间 & 空间复杂度",
              dis: "各种情况的时间复杂度和空间复杂度分析",
              children: [
                "1、算法，时间&空间复杂度-算法和数据结构",
                "2、算法，时间&空间复杂度-时间复杂度",
                "3、算法，时间&空间复杂度-空间复杂度",
              ],
            },
            {
              weekname: "第 3 课 算法必备基础",
              dis: "递归，迭代，分治",
              children: [
                "1、算法必备基础-数据量级",
                "2、算法必备基础-递归",
                "3、算法必备基础-递归的时间和空间复杂度",
                "4、算法必备基础-重复调用怎么办",
                "5、算法必备基础-迭代",
                "6、算法必备基础-分治法",
                "7、算法必备基础-特殊的时间和空间复杂度",
              ],
            },
            {
              weekname: "第 4 章 数组",
              dis: "数组的实现和Java源码分析",
              children: [
                "1、数组-数组介绍",
                "2、数组-实现数组 - 基本方法",
                "3、数组-实现数组 - 增加",
                "4、数组-实现数组 - 查找",
                "5、数组-实现数组 - 修改",
                "6、数组-实现数组 - 删除",
                "7、数组-实现数组 - 优化和测试",
                "8、数组-数组的时间复杂度",
                "9、数组-数组动态化 + Lazy 思想",
                "10、数组-均摊时间 VS 平均时间",
                "11、数组-Java 源码分析 - ArrayList",
                "12、数组-Java 源码分析 - add()",
                "13、数组-Java 源码分析 - contains & get & set",
                "14、数组-Java 源码分析 - remove()",
                "15、数组-Leetcode 相关题目",
              ],
            },
          ],
        },
        {
          name: "第 5 课 ~ 第 8 课",
          weeks: [
            {
              weekname: "第 5 课 基于比较的排序算法",
              dis: "合并，快速，冒泡，选择，插入排序",
              children: [
                "1、基于比较的排序算法-排序算法",
                "2、基于比较的排序算法-冒泡排序",
                "3、基于比较的排序算法-排序算法的稳定性",
                "4、基于比较的排序算法-类中比较 Comparable 和 Comparator",
                "5、基于比较的排序算法-Leetcode 中的冒泡排序",
                "6、基于比较的排序算法-选择排序",
                "7、基于比较的排序算法-Leetcode 中的选择排序",
                "8、基于比较的排序算法-插入排序",
                "9、基于比较的排序算法-插入排序的二分优化",
                "10、基于比较的排序算法-Leetcode 中的插入排序",
                "11、基于比较的排序算法-合并排序",
                "12、基于比较的排序算法-Leetcode 中的合并排序",
                "13、基于比较的排序算法-快速排序",
                "14、基于比较的排序算法-Leetcode 中的快速排序",
              ],
            },
            {
              weekname: "第 6 课 非比较的排序算法",
              dis: "计数，桶排序，Java源码分析",
              children: [
                "1、非比较的排序算法和Java数组相关源码-计数排序",
                "2、非比较的排序算法和Java数组相关源码-Leetcode 中的计数排序",
                "3、非比较的排序算法和Java数组相关源码-桶排序",
                "4、非比较的排序算法和Java数组相关源码-Leetcode 中的桶排序",
                "5、非比较的排序算法和Java数组相关源码-排序算法总结和荷兰国旗问题",
                "6、非比较的排序算法和Java数组相关源码-Java 源码 Collections 集合",
                "7、非比较的排序算法和Java数组相关源码-Java 源码 Arrays 方法",
                "8、非比较的排序算法和Java数组相关源码-Java 源码 Arrays.sort 分析",
                "9、非比较的排序算法和Java数组相关源码-Arrays.sort 比较",
              ],
            },
            {
              weekname: "第 7 课 链表",
              dis: "链表的介绍和应用",
              children: [
                "1、链表-什么是链表",
                "2、链表-链表的插入",
                "3、链表-链表的删除",
                "4、链表-链表的更改",
                "5、链表-链表的查找",
                "6、链表-范型的更改",
                "7、链表-时间复杂度和测试",
                "8、链表-静态，双向，循环，双向循环链表",
                "9、链表-Java 源码 LinkedList - add 方法",
                "10、链表-Java 源码 LinkedList - remove 方法",
                "11、链表-Java 源码 LinkedList - get 和 set 方法",
                "12、链表-Java 源码接口 List",
                "13、链表-ArrayList VS LinkedList",
              ],
            },
            {
              weekname: "第 8 课 栈",
              dis: "栈的介绍和Java源码分析",
              children: [
                "1、栈-栈的介绍",
                "2、栈-顺序栈的简单实现",
                "3、栈-顺序栈的具体实现",
                "4、栈-链式栈的具体实现",
                "5、栈-Java 源码分析 Stack 和 Vector VS ArrayList",
                "6、栈-栈和递归",
                "7、栈-递归转化迭代",
                "8、栈-Leetcode 中的 Stack",
                "9、栈-题型讲解",
              ],
            },
          ],
        },
        {
          name: "第 9 课 ~ 第 12 课",
          weeks: [
            {
              weekname: "第 9 课 队列",
              dis: "队列的介绍和Java源码分析",
              children: [
                "1、队列-什么是队列",
                "2、队列-Deque Queue Stack",
                "3、队列-Java 源码分析 Queue Deque",
                "4、队列-链表实现队列代码实现",
                "5、队列-数组实现队列",
                "6、队列-循环队列介绍",
                "7、队列-循环队列的代码实现",
                "8、队列-Java 源码 ArrayDeque 分析",
                "9、队列-Leetcode 中的 Queue 和 Stack",
                "10、队列-队列的应用",
              ],
            },
            {
              weekname: "第 10 课 散列表基础知识",
              dis: "HashTable的基础，HashMap，HashSet，HashTable",
              children: [
                "1、散列表基础知识-HashTable HashMap HashSet 简介",
                "2、散列表基础知识-直接寻址表和散列表",
                "3、散列表基础知识-散列函数",
                "4、散列表基础知识-链接法",
                "5、散列表基础知识-碰撞过多怎么办",
                "6、散列表基础知识-开放地址法",
                "7、散列表基础知识-再哈希法和小结",
              ],
            },
            {
              weekname: "第 11 课 散列表代码实现",
              dis: "hashCode，HashMap，HashSet等Java源码分析",
              children: [
                "1、散列表代码实现-hashCode 和 equals",
                "2、散列表代码实现-链接法代码",
                "3、散列表代码实现-开放定址法代码实现",
                "4、散列表代码实现-HashTable 源码分析",
                "5、散列表代码实现-HashMap 源码分析",
                "6、散列表代码实现-HashSet 源码分析",
                "7、散列表代码实现-LinkedHashMap 简介",
                "8、散列表代码实现-Leetcode 实战练习题",
                "9、散列表代码实现-Bloom Filter 布隆过滤器",
                "10、散列表代码实现-Bloom Filter 布隆过滤器代码实现",
                "11、散列表代码实现-做题须知",
              ],
            },
            {
              weekname: "第 12 课 堆",
              dis: "Heap 的原理及实现",
              children: [
                "1、堆-堆的介绍",
                "2、堆-保持堆的性质",
                "3、堆-其他函数的实现",
                "4、堆-Heapify 过程",
                "5、堆-堆排序",
                "6、堆-排序算法时间下界",
                "7、堆-PriorityQueue 源码分析",
                "8、堆-索引堆",
                "9、堆-索引堆应用",
              ],
            },
          ],
        },
        {
          name: "第 13 课 ~ 第 16 课",
          weeks: [
            {
              weekname: "第 13 课 图",
              dis: "图的原理及实现方式",
              children: [
                "1、图-图",
                "2、图-图的表示",
                "3、图-深度优先搜索原理",
                "4、图-DFS 递归代码实现1",
                "5、图-DFS 递归代码实现2",
                "6、图-迭代代码实现",
                "7、图-DFS 复杂度分析",
                "8、图-广度优先搜索原理",
                "9、图-BFS 代码实现",
                "10、图-代码实现和 BFS 复杂度分析",
                "11、图-遍历应用和实战演练",
              ],
            },
            {
              weekname: "第 14 课 拓扑排序",
              dis: "图的原理及实现方式",
              children: [
                "1、拓扑排序-拓扑排序",
                "2、拓扑排序-DFS 实现拓扑排序",
                "3、拓扑排序-DFS 拓扑排序代码实现",
                "4、拓扑排序-BFS 实现拓扑排序",
                "5、拓扑排序-BFS 拓扑排序代码实现",
                "6、拓扑排序-拓扑排序复杂度",
              ],
            },
            {
              weekname: "第 15 课 并查集",
              dis: "Union Find 的原理及实现",
              children: [
                "1、并查集-Union Find 简介",
                "2、并查集-Quick Find 实现",
                "3、并查集-Quick Union 实现",
                "4、并查集-Union Find 两种优化方式",
                "5、并查集-Union Find 总结",
              ],
            },
            {
              weekname: "第 16 课 最小生成树",
              dis: "Prim 和 Kruskal 算法",
              children: [
                "1、最小生成树-最小生成树介绍",
                "2、最小生成树-Prim 算法介绍",
                "3、最小生成树-边，图代码实现",
                "4、最小生成树-Prim 代码实现",
                "5、最小生成树-Kruskal 算法介绍",
                "6、最小生成树-Kruskal 代码实现",
              ],
            },
          ],
        },
        {
          name: "第 17 课 ~ 第 20 课",
          weeks: [
            {
              weekname: "第 17 课 最短路径",
              dis: "Dijkstra，Bellman-Ford，Floyd-Warshall",
              children: [
                "1、最短路径-最短路径介绍",
                "2、最短路径-Dijkstra 原理",
                "3、最短路径-Dijkstra 算法实现1",
                "4、最短路径-Dijkstra 算法实现2",
                "5、最短路径-Dijkstra 时间复杂度",
                "6、最短路径-Bellman-Ford 算法原理",
                "7、最短路径-Bellman-Ford 代码实现",
                "8、最短路径-Floyd-Warshall 算法介绍",
                "9、最短路径-图的总结",
              ],
            },
            {
              weekname: "第 18 课 树",
              dis: "树，二叉树，二叉搜索树",
              children: [
                "1、树-树的概念",
                "2、树-先序遍历",
                "3、树-中序遍历",
                "4、树-后序遍历",
                "5、树-层次遍历",
                "6、树-二叉树表示——链表形式",
                "7、树-二叉查找树",
                "8、树-二叉查找树的插入",
                "9、树-二叉查找树的查询",
                "10、树-二叉查找树的删除",
                "11、树-补充和小结",
              ],
            },
            {
              weekname: "第 19 课 AVL树",
              dis: "AVL树的源码和代码",
              children: [
                "1、AVL树-AVL树",
                "2、AVL树-AVL树实现",
                "3、AVL树-AVL 的 LL 和 RR",
                "4、AVL树-AVL 的 LL 和 RR 代码实现",
                "5、AVL树-AVL 的 LR 和 RL 代码实现",
                "6、AVL树-AVL的删除和包含",
              ],
            },
            {
              weekname: "第 20 课 红黑树",
              dis: "红黑树的原理和代码",
              children: [
                "1、红黑树-2-3树",
                "2、红黑树-2-3树与红黑树",
                "3、红黑树-基础代码",
                "4、红黑树-红黑树的加入",
                "5、红黑树-旋转，颜色反转颜色代码",
                "6、红黑树-插入代码实现",
                "7、红黑树-红黑树测试和总结",
                "8、红黑树-Java 中的红黑树——TreeMap",
                "9、红黑树-TreeMap 中的特有函数",
              ],
            },
          ],
        },
      ],
      //求职面试刷题班
      algorithmExerciseClass: [
        {
          name: "第 1 课 ~ 第 4 课",
          weeks: [
            {
              weekname: "第 1 课 位运算",
              dis: "位运算的考点和扩展延伸",
              children: [
                "1、位运算-二进制",
                "2、位运算-Java 八大基本类型",
                "3、位运算-位运算",
                "4、位运算-编码标准",
                "5、位运算-面试技巧1 - XOR",
                "6、位运算-面试技巧2 - n&(n - 1)",
                "7、位运算-面试技巧3 - n&1",
                "8、位运算-数据量过大怎么办",
                "9、位运算-BitMap 应用",
                "10、位运算-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 2 课 数学题",
              dis: "数学题考点讲解",
              children: [
                "1、数学题-数学题概括",
                "2、数学题-考察要点1 - 越界",
                "3、数学题-考察要点2 - 进位",
                "4、数学题-考察要点3 - 符号字母",
                "5、数学题-常考题型 - 开方（牛顿法）",
                "6、数学题-特殊题型和必背代码",
                "7、数学题-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 3 课 数组题",
              dis: "数组常见题型和补充算法",
              children: [
                "1、数组题-常考题型 1 - 排序算法补充",
                "2、数组题-常考题型 2 - 单向双指针",
                "3、数组题-扫描线算法",
                "4、数组题-子数组 - 进行中处理",
                "5、数组题-子数组 - 分段式处理",
                "6、数组题-双指针 - 双向双指针",
                "7、数组题-常考题型 3 - 二维数组",
                "8、数组题-二维矩阵 - 旋转变换",
                "9、数组题-常考题型4 - 实现题",
                "10、数组题-数学定理",
                "11、数组题-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 4 课 二分查找",
              dis: "二分查找三种写法区别",
              children: [
                "1、二分查找-二分查找",
                "2、二分查找-递归写法",
                "3、二分查找-迭代写法 1：left <= right",
                "4、二分查找-迭代写法 2：left < right",
                "5、二分查找-迭代写法 3：left + 1< right",
                "6、二分查找-Java 源码中的二分查找",
                "7、二分查找-三种方法应用",
                "8、二分查找-Rotate题型",
                "9、二分查找-Rotate题型 follow up",
                "10、二分查找-二维数组",
                "11、二分查找-Leetcode作业题和思维导图",
              ],
            },
          ],
        },
        {
          name: "第 5 课 ~ 第 8 课",
          weeks: [
            {
              weekname: "第 5 课 链表",
              dis: "链表常考题型",
              children: [
                "1、链表-链表题型介绍",
                "2、链表-常考题型 1 - 基本操作",
                "3、链表-常考题型 2 - 反转",
                "4、链表-常考题型 3 - 环",
                "5、链表-常考题型 4 - 删除",
                "6、链表-链表的递归实现",
                "7、链表-综合练习",
                "8、链表-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 6 课 随机",
              dis: "等概率抽取法和蓄水池算法",
              children: [
                "1、随机-等概率抽取法",
                "2、随机-Leetcode 中的等概率抽取法",
                "3、随机-蓄水池抽样1 - 选取一个",
                "4、随机-蓄水池抽样2 - 选取多个",
                "5、随机-蓄水池算法3 - 代码实现",
                "6、随机-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 7 课 栈",
              dis: "栈常考题型分类划分",
              children: [
                "1、栈-常考题型1 - 平衡符号",
                "2、栈-常考题型2 - 压栈匹配1",
                "3、栈-常考题型2 - 压栈匹配2",
                "4、栈-常考题型3 - 表达式计算1",
                "5、栈-常考题型3 - 表达式计算2",
                "6、栈-常考题型4 - 迭代极值1",
                "7、栈-常考题型4 - 迭代极值2",
                "8、栈-Leetcode 400题作业，思维导图",
              ],
            },
            {
              weekname: "第 8 课 字符串",
              dis: "字符串常考题型分类划分",
              children: [
                "1、字符串-String 基本函数",
                "2、字符串-字符串函数时间复杂度",
                "3、字符串-正则表达式",
                "4、字符串-转义字符 & 通配符",
                "5、字符串-String VS StringBuilder VS StringBuffer",
                "6、字符串-常考题型1 - Anagram",
                "7、字符串-常考题型2 - Substring",
                "8、字符串-常考题型3 - Parentheses",
                "9、字符串-常考题型4 - Palindrome",
                "10、字符串-常考题型5 - Subsequence",
                "11、字符串-常考题型6 -实现题",
                "12、字符串-Leetcode 400题作业，思维导图",
              ],
            },
          ],
        },
        {
          name: "第 9 课 ~ 第 12 课",
          weeks: [
            {
              weekname: "第 9 课 图形",
              dis: "点，线，面，到各种图形考点",
              children: [
                "1、图形-图形题简介",
                "2、图形-点在线段上",
                "3、图形-三点方向",
                "4、图形-点在直线上",
                "5、图形-点与面的关系",
                "6、图形-线段是否相交",
                "7、图形-点是否在多边形内",
                "8、图形-圆简介",
                "9、图形-方格与圆",
                "10、图形-圆与三角形",
                "11、图形-多边形考点",
                "12、图形-二分图",
                "13、图形-正方形",
                "14、图形-长方形",
                "15、图形-不平行于x轴怎么办",
                "16、图形-三角形和小结",
              ],
            },
            {
              weekname: "第 10 课 堆",
              dis: "PriorityQueue 的各种用法",
              children: [
                "1、堆-PriorityQueue 写法介绍",
                "2、堆-全排序 VS 局部排序",
                "3、堆-Arrays.sort 应用",
                "4、堆-Pair 和时间复杂度",
                "5、堆-类的应用——外排序",
                "6、堆-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 11 课 回溯法",
              dis: "回溯三大原始题型和实现题",
              children: [
                "1、回溯法-回溯概念",
                "2、回溯法-回溯三大题型——排列",
                "3、回溯法-回溯三大题型——子集",
                "4、回溯法-回溯三大题型——组合",
                "5、回溯法-模版和值传递",
                "6、回溯法-回溯法的实现题",
                "7、回溯法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 12 课 图",
              dis: "图的Flood Fill，BFS，匈牙利算法",
              children: [
                "1、图-图的基础回顾",
                "2、图-Flood Fill 的 DFS",
                "3、图-Flood Fill 的 BFS",
                "4、图-BFS 最终优化",
                "5、图-迷宫，棋盘怎么破",
                "6、图-双向广度搜索",
                "7、图-思考方式——转换图",
                "8、图-匹配",
                "9、图-匈牙利算法原理",
                "10、图-匈牙利算法实现",
                "11、图-Leetcode 作业题和思维导图",
              ],
            },
          ],
        },
        {
          name: "第 13 课 ~ 第 16 课",
          weeks: [
            {
              weekname: "第 13 课 树题型",
              dis: "树的遍历，常考题型",
              children: [
                "1、树-树的第一想法",
                "2、树-双 Pre 题目和解法",
                "3、树-Inorder 题目和解法",
                "4、树-Postorder 题目和解法",
                "5、树-Levelorder 题目和解法",
                "6、树-Preorder 和 Backtracking",
                "7、树-二叉搜索树",
                "8、树-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 14 课 字典树",
              dis: "Trie的知识和考点",
              children: [
                "1、字典树-Trie 介绍",
                "2、字典树-TrieNode",
                "3、字典树-add() 和 contains() 函数",
                "4、字典树-前缀树——startsWith() 函数",
                "5、字典树-字符匹配——search() 函数",
                "6、字典树-字典结合",
                "7、字典树-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 15 课 线段树和树状数组",
              dis: "线段树的实现方式，树状数组的原理及应用",
              children: [
                "1、线段树和树状数组-Segment Tree 和 Binary Index Tree",
                "2、线段树和树状数组-线段树原理",
                "3、线段树和树状数组-SegmentTreeNode 实现 Segment Tree",
                "4、线段树和树状数组-数组实现 Segment Tree",
                "5、线段树和树状数组-lazy 思想",
                "6、线段树和树状数组-Binary Index Tree 原理",
                "7、线段树和树状数组-实现 Binary Index Tree",
                "8、线段树和树状数组-BIT 和 ST 对比",
                "9、线段树和树状数组-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 16 课 贪心算法",
              dis: "贪心的原理和应用",
              children: [
                "1、贪心算法-贪心算法——贪心思想",
                "2、贪心算法-贪心算法解法步骤",
                "3、贪心算法-贪心的局限性",
                "4、贪心算法-Leetcode 作业题和思维导图",
              ],
            },
          ],
        },
        {
          name: "第 17 课 ~ 第 18 课",
          weeks: [
            {
              weekname: "第 17 课 设计",
              dis: "设计题技巧和总结",
              children: [
                "1、设计-设计介绍——10种数据结构",
                "2、设计-HashSet VS HashMap",
                "3、设计-ArrayList VS LinkedList",
                "4、设计-Stack VS Queue VS Deque",
                "5、设计-TreeSet VS TreeMap VS PriorityQueue",
                "6、设计-设置 Class",
                "7、设计-做题思想和做题流程总结",
                "8、设计-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 18 课 动态规划",
              dis: "如何一步步推导出递归，最难题型",
              children: [
                "1、动态规划-如何推到出动态规划",
                "2、动态规划-Backtracking - Memorization - DP（1）",
                "3、动态规划-Backtracking - Memorization - DP（2）",
                "4、动态规划-三种方法小结",
                "5、动态规划-状态和状态转移方程",
                "6、动态规划-动态规划做题思路",
                "7、动态规划-01背包问题",
                "8、动态规划-01背包问题分析及其优化",
                "9、动态规划-完全背包——两种解决方法",
                "10、动态规划-多重背包和混合背包",
                "11、动态规划-字符串问题",
                "12、动态规划-网格问题",
                "13、动态规划-匹配问题",
                "14、动态规划-follow up —— 打印中间过程",
                "15、动态规划-贪心算法和动态规划",
                "16、动态规划-Leetcode 作业题和思维导图",
              ],
            },
          ],
        },
      ],
      //面向对象设计班
      OODDesignPattern:[
        {
          name: "OOD 面向对象设计",
          weeks: [
            {
              weekname: "第 1 课 对象三要素",
              dis: "面向对象三大要素",
              children: [
                "1、对象三要素——封装",
                "2、对象三要素——继承",
                "3、组合关系和聚合关系",
                "4、关联关系和依赖关系",
                "5、实现和抽象",
                "6、对象三要素——多态",
              ],
            },
            {
              weekname: "第 2 课 UML",
              dis: "UML",
              children: [
                "1、UML介绍",
                "2、用例图，顺序图，活动图",
                "3、类图的表示",
                "4、类与类之间的关系",
                "5、类图实例",
                "6、类图实例代码",
              ],
            },
            {
              weekname: "第 3 课 面向对象五大原则（上）",
              dis: "面向对象五大原则，外加两大原则",
              children: [
                "1、面向对象五大原则",
                "2、开放封闭原则构建",
                "3、开放封闭原则代码实现",
                "4、依赖倒置原则介绍",
                "5、依赖倒置原则代码实现1",
                "6、依赖的三种方法",
                "7、依赖倒置原则总结",
                "8、单一职责原则代码实现",
                "9、单一职责原则总结",
              ],
            },
            {
              weekname: "第 4 课 面向对象五大原则（下）",
              dis: "面向对象五大原则，外加两大原则",
              children: [
                "1、接口隔离原则",
                "2、接口隔离原则代码实现",
                "3、接口隔离原则总结",
                "4、里氏替换原则",
                "5、里氏替换原则代码实现",
                "6、里氏替换原则总结",
                "7、迪米特原则",
                "8、合成复用原则",
                "9、面向对象原则总结",
              ],
            },
            {
              weekname: "第 5 课 Parking Lot 停车场",
              dis: "Parking Lot 停车场",
              children: [
                "1、OOD万能解法",
                "2、OOD加分项1",
                "3、OOD加分项2",
                "4、Method的表达",
                "5、OOD加分项3",
                "5、面试总结",
              ],
            },
            {
              weekname: "第 6 课 Elevator 电梯",
              dis: "Elevator 电梯",
              children: [
                "1、三步解法1",
                "2、三步万能解法2",
                "3、4种电梯算法",
                "4、三步万能解法3",
              ],
            },
            {
              weekname: "第 7 课 Amazon Locker 亚马逊快递柜",
              dis: "Amazon Locker 亚马逊快递柜",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
                "3、三步万能解法3",
              ],
            },
            {
              weekname: "第 8 课 Vending Machine 自动售货机",
              dis: "Vending Machine 自动售货机",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
              ],
            },
            {
              weekname: "第 9 课 Restaurant Reservation System 餐厅预订系统",
              dis: "Restaurant Reservation System 餐厅预订系统",
              children: [
                "1、预定类讲解1",
                "2、预定类讲解2",
                "3、预定类讲解3",
              ],
            },
            {
              weekname: "第 10 课 Hotel Reservation System 酒店预定系统",
              dis: "Hotel Reservation System 酒店预定系统",
              children: [
                "1、Hotel Reservation",
              ],
            },
            {
              weekname: "第 11 课 Movie Ticket Booking System 电影票订票系统",
              dis: "Movie Ticket Booking System 电影票订票系统",
              children: [
                "1、Movie Ticket 1",
                "2、Movie Ticket 2",
              ],
            },
            {
              weekname: "第 12 课 Tic Tac Toe 井字游戏",
              dis: "Tic Tac Toe 井字游戏",
              children: [
                "1、TicTacToe",
              ],
            },
            {
              weekname: "第 13 课 Chess 国际象棋",
              dis: "Chess 国际象棋",
              children: [
                "1、Chess 1",
                "2、Chess 2",
              ],
            },
            {
              weekname: "第 14 课 Blackjack 21点纸牌游戏",
              dis: "Blackjack 21点纸牌游戏",
              children: [
                "1、Blackjack",
              ],
            },
            {
              weekname: "第 15 课 ATM ATM机",
              dis: "ATM ATM机",
              children: [
                "1、ATM",
              ],
            },
            {
              weekname: "第 16 课  Online Book Reader System 在线图书系统",
              dis: "Online Book Reader System 在线图书系统",
              children: [
                "1、Online Book Reader System",
              ],
            },
          ],
        },
        {
          name: "Gof 23种模式",
          weeks: [
            {
              weekname: "第 1 课 工厂模式",
              dis: "工厂模式",
              children: [
                "1、简单工厂模式",
                "2、工厂方法模式",
                "3、抽象工厂模式",
              ],
            },
            {
              weekname: "第 2 课 建造者模式",
              dis: "建造者模式",
              children: [
                "1、建造者模式代码",
                "2、建造者模式总结",
              ],
            },
            {
              weekname: "第 3 课 单例模式",
              dis: "单例模式",
              children: [
                "1、单例模式",
                "2、八种写法——饿汉式2种",
                "3、八种写法——懒汉式+多线程Debug",
                "4、八种写法——懒汉式+同步2种",
                "5、八种写法——双重检查",
                "6、八种写法——静态内部类",
                "7、八种写法——枚举",
                "8、单例模式总结",
              ],
            },
            {
              weekname: "第 4 课 原型模式",
              dis: "原型模式",
              children: [
                "1、原型模式",
                "2、深克隆 VS 浅克隆和其他考点",
                "3、原型模式总结",
              ],
            },
            {
              weekname: "第 5 课 适配器模式",
              dis: "适配器模式",
              children: [
                "1、适配器模式——类",
                "2、适配器模式——对象",
              ],
            },
            {
              weekname: "第 6 课 Gof 23种设计模式（1）",
              dis: "外观模式，装饰者模式，组合模式，享元模式",
              children: [
                "1、外观模式",
                "2、装饰者模式",
                "3、组合模式",
                "4、享元模式",
              ],
            },
            {
              weekname: "第 7 课 Gof 23种设计模式（2）",
              dis: "桥接模式，代理模式，迭代器模式，解释器模式",
              children: [
                "1、桥接模式",
                "2、代理模式",
                "3、迭代器模式",
                "4、解释器模式",
              ],
            },
            {
              weekname: "第 8 课 Gof 23种设计模式（3）",
              dis: "访问者模式，中介者模式，备忘录模式，观察者模式",
              children: [
                "1、访问者模式",
                "2、中介者模式",
                "3、备忘录模式",
                "4、观察者模式",
              ],
            },
            {
              weekname: "第 11 课 Gof 23种设计模式（4）",
              dis: "命令模式，责任链模式，状态模式，策略模式，模板方法模式",
              children: [
                "1、命令模式",
                "2、责任链模式",
                "3、状态模式",
                "4、策略模式",
                "5、模板方法模式",
              ],
            },
          ],
        },
      ],
      //北美SDE算法速成班
      crashClass: [
        {
          name: "第 1 课 ~ 第 4 课",
          weeks: [
            {
              weekname: "第 1 课 算法，时间 & 空间复杂度",
              dis: "各种情况的时间复杂度和空间复杂度分析",
              children: [
                "1、算法，时间&空间复杂度-算法和数据结构",
                "2、算法，时间&空间复杂度-时间复杂度",
                "3、算法，时间&空间复杂度-空间复杂度",
              ],
            },
            {
              weekname: "第 2 课 算法必备基础",
              dis: "递归，迭代，分治",
              children: [
                "1、算法必备基础-数据量级",
                "2、算法必备基础-递归",
                "3、算法必备基础-递归的时间和空间复杂度",
                "4、算法必备基础-重复调用怎么办",
                "5、算法必备基础-迭代",
                "6、算法必备基础-分治法",
                "7、算法必备基础-特殊的时间和空间复杂度",
              ],
            },
            {
              weekname: "第 3 章 数组",
              dis: "数组的实现和Java源码分析",
              children: [
                "1、数组-数组介绍",
                "2、数组-实现数组 - 基本方法",
                "3、数组-实现数组 - 增加",
                "4、数组-实现数组 - 查找",
                "5、数组-实现数组 - 修改",
                "6、数组-实现数组 - 删除",
                "7、数组-实现数组 - 优化和测试",
                "8、数组-数组的时间复杂度",
                "9、数组-数组动态化 + Lazy 思想",
                "10、数组-均摊时间 VS 平均时间",
                "11、数组-Java 源码分析 - ArrayList",
                "12、数组-Java 源码分析 - add()",
                "13、数组-Java 源码分析 - contains & get & set",
                "14、数组-Java 源码分析 - remove()",
                "15、数组-Leetcode 相关题目",
              ],
            },
            {
              weekname: "第 4 课 位运算",
              dis: "位运算的考点和扩展延伸",
              children: [
                "1、位运算-二进制",
                "2、位运算-Java 八大基本类型",
                "3、位运算-位运算",
                "4、位运算-编码标准",
                "5、位运算-面试技巧1 - XOR",
                "6、位运算-面试技巧2 - n&(n - 1)",
                "7、位运算-面试技巧3 - n&1",
                "8、位运算-数据量过大怎么办",
                "9、位运算-BitMap 应用",
                "10、位运算-Leetcode作业题和思维导图",
              ],
            },
          ],
        },
        {
          name: "第 5 课 ~ 第 8 课",
          weeks: [
            {
              weekname: "第 5 课 数学题",
              dis: "数学题考点讲解",
              children: [
                "1、数学题-数学题概括",
                "2、数学题-考察要点1 - 越界",
                "3、数学题-考察要点2 - 进位",
                "4、数学题-考察要点3 - 符号字母",
                "5、数学题-常考题型 - 开方（牛顿法）",
                "6、数学题-特殊题型和必背代码",
                "7、数学题-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 6 课 基于比较的排序算法",
              dis: "合并，快速，冒泡，选择，插入排序",
              children: [
                "1、基于比较的排序算法-排序算法",
                "2、基于比较的排序算法-冒泡排序",
                "3、基于比较的排序算法-排序算法的稳定性",
                "4、基于比较的排序算法-类中比较 Comparable 和 Comparator",
                "5、基于比较的排序算法-Leetcode 中的冒泡排序",
                "6、基于比较的排序算法-选择排序",
                "7、基于比较的排序算法-Leetcode 中的选择排序",
                "8、基于比较的排序算法-插入排序",
                "9、基于比较的排序算法-插入排序的二分优化",
                "10、基于比较的排序算法-Leetcode 中的插入排序",
                "11、基于比较的排序算法-合并排序",
                "12、基于比较的排序算法-Leetcode 中的合并排序",
                "13、基于比较的排序算法-快速排序",
                "14、基于比较的排序算法-Leetcode 中的快速排序",
              ],
            },
            {
              weekname: "第 7 课 非比较的排序算法",
              dis: "计数，桶排序，Java源码分析",
              children: [
                "1、非比较的排序算法和Java数组相关源码-计数排序",
                "2、非比较的排序算法和Java数组相关源码-Leetcode 中的计数排序",
                "3、非比较的排序算法和Java数组相关源码-桶排序",
                "4、非比较的排序算法和Java数组相关源码-Leetcode 中的桶排序",
                "5、非比较的排序算法和Java数组相关源码-排序算法总结和荷兰国旗问题",
                "6、非比较的排序算法和Java数组相关源码-Java 源码 Collections 集合",
                "7、非比较的排序算法和Java数组相关源码-Java 源码 Arrays 方法",
                "8、非比较的排序算法和Java数组相关源码-Java 源码 Arrays.sort 分析",
                "9、非比较的排序算法和Java数组相关源码-Arrays.sort 比较",
              ],
            },
            {
              weekname: "第 8 课 数组题",
              dis: "数组常见题型和补充算法",
              children: [
                "1、数组题-常考题型 1 - 排序算法补充",
                "2、数组题-常考题型 2 - 单向双指针",
                "3、数组题-扫描线算法",
                "4、数组题-子数组 - 进行中处理",
                "5、数组题-子数组 - 分段式处理",
                "6、数组题-双指针 - 双向双指针",
                "7、数组题-常考题型 3 - 二维数组",
                "8、数组题-二维矩阵 - 旋转变换",
                "9、数组题-常考题型4 - 实现题",
                "10、数组题-数学定理",
                "11、数组题-Leetcode作业题和思维导图",
              ],
            },
          ],
        },
        {
          name: "第 9 课 ~ 第 12 课",
          weeks: [
            {
              weekname: "第 9 课 链表",
              dis: "链表的介绍和应用",
              children: [
                "1、链表-什么是链表",
                "2、链表-链表的插入",
                "3、链表-链表的删除",
                "4、链表-链表的更改",
                "5、链表-链表的查找",
                "6、链表-范型的更改",
                "7、链表-时间复杂度和测试",
                "8、链表-静态，双向，循环，双向循环链表",
                "9、链表-Java 源码 LinkedList - add 方法",
                "10、链表-Java 源码 LinkedList - remove 方法",
                "11、链表-Java 源码 LinkedList - get 和 set 方法",
                "12、链表-Java 源码接口 List",
                "13、链表-ArrayList VS LinkedList",
              ],
            },
            {
              weekname: "第 10 课 二分查找",
              dis: "二分查找三种写法区别",
              children: [
                "1、二分查找-二分查找",
                "2、二分查找-递归写法",
                "3、二分查找-迭代写法 1：left <= right",
                "4、二分查找-迭代写法 2：left < right",
                "5、二分查找-迭代写法 3：left + 1< right",
                "6、二分查找-Java 源码中的二分查找",
                "7、二分查找-三种方法应用",
                "8、二分查找-Rotate题型",
                "9、二分查找-Rotate题型 follow up",
                "10、二分查找-二维数组",
                "11、二分查找-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 11 课 链表",
              dis: "链表常考题型",
              children: [
                "1、链表-链表题型介绍",
                "2、链表-常考题型 1 - 基本操作",
                "3、链表-常考题型 2 - 反转",
                "4、链表-常考题型 3 - 环",
                "5、链表-常考题型 4 - 删除",
                "6、链表-链表的递归实现",
                "7、链表-综合练习",
                "8、链表-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 12 课 随机",
              dis: "等概率抽取法和蓄水池算法",
              children: [
                "1、随机-等概率抽取法",
                "2、随机-Leetcode 中的等概率抽取法",
                "3、随机-蓄水池抽样1 - 选取一个",
                "4、随机-蓄水池抽样2 - 选取多个",
                "5、随机-蓄水池算法3 - 代码实现",
                "6、随机-Leetcode作业题和思维导图",
              ],
            },
          ],
        },
        {
          name: "第 13 课 ~ 第 16 课",
          weeks: [
            {
              weekname: "第 13 课 栈",
              dis: "栈的介绍和Java源码分析",
              children: [
                "1、栈-栈的介绍",
                "2、栈-顺序栈的简单实现",
                "3、栈-顺序栈的具体实现",
                "4、栈-链式栈的具体实现",
                "5、栈-Java 源码分析 Stack 和 Vector VS ArrayList",
                "6、栈-栈和递归",
                "7、栈-递归转化迭代",
                "8、栈-Leetcode 中的 Stack",
                "9、栈-题型讲解",
              ],
            },
            {
              weekname: "第 14 课 队列",
              dis: "队列的介绍和Java源码分析",
              children: [
                "1、队列-什么是队列",
                "2、队列-Deque Queue Stack",
                "3、队列-Java 源码分析 Queue Deque",
                "4、队列-链表实现队列代码实现",
                "5、队列-数组实现队列",
                "6、队列-循环队列介绍",
                "7、队列-循环队列的代码实现",
                "8、队列-Java 源码 ArrayDeque 分析",
                "9、队列-Leetcode 中的 Queue 和 Stack",
                "10、队列-队列的应用",
              ],
            },
            {
              weekname: "第 15 课 栈",
              dis: "栈常考题型分类划分",
              children: [
                "1、栈-常考题型1 - 平衡符号",
                "2、栈-常考题型2 - 压栈匹配1",
                "3、栈-常考题型2 - 压栈匹配2",
                "4、栈-常考题型3 - 表达式计算1",
                "5、栈-常考题型3 - 表达式计算2",
                "6、栈-常考题型4 - 迭代极值1",
                "7、栈-常考题型4 - 迭代极值2",
                "8、栈-Leetcode 400题作业，思维导图",
              ],
            },
            {
              weekname: "第 16 课 字符串",
              dis: "字符串常考题型分类划分",
              children: [
                "1、字符串-String 基本函数",
                "2、字符串-字符串函数时间复杂度",
                "3、字符串-正则表达式",
                "4、字符串-转义字符 & 通配符",
                "5、字符串-String VS StringBuilder VS StringBuffer",
                "6、字符串-常考题型1 - Anagram",
                "7、字符串-常考题型2 - Substring",
                "8、字符串-常考题型3 - Parentheses",
                "9、字符串-常考题型4 - Palindrome",
                "10、字符串-常考题型5 - Subsequence",
                "11、字符串-常考题型6 -实现题",
                "12、字符串-Leetcode 400题作业，思维导图",
              ],
            },
          ],
        },
        {
          name: "第 17 课 ~ 第 20 课",
          weeks: [
            {
              weekname: "第 17 课 面试技巧指导",
              dis: "面试时如何和面试官交流，做题如何表现",
              children: [
                "1、面试流程——实习全职",
                "2、面试内容和流程",
                "3、题意",
                "4、输入输出，命名",
                "5、做题注意事项",
                "6、复杂度 & Case",
              ],
            },
            {
              weekname: "第 18 课 散列表基础知识",
              dis: "HashTable的基础，HashMap，HashSet，HashTable",
              children: [
                "1、散列表基础知识-HashTable HashMap HashSet 简介",
                "2、散列表基础知识-直接寻址表和散列表",
                "3、散列表基础知识-散列函数",
                "4、散列表基础知识-链接法",
                "5、散列表基础知识-碰撞过多怎么办",
                "6、散列表基础知识-开放地址法",
                "7、散列表基础知识-再哈希法和小结",
              ],
            },
            {
              weekname: "第 19 课 散列表代码实现",
              dis: "hashCode，HashMap，HashSet等Java源码分析",
              children: [
                "1、散列表代码实现-hashCode 和 equals",
                "2、散列表代码实现-链接法代码",
                "3、散列表代码实现-开放定址法代码实现",
                "4、散列表代码实现-HashTable 源码分析",
                "5、散列表代码实现-HashMap 源码分析",
                "6、散列表代码实现-HashSet 源码分析",
                "7、散列表代码实现-LinkedHashMap 简介",
                "8、散列表代码实现-Leetcode 实战练习题",
                "9、散列表代码实现-Bloom Filter 布隆过滤器",
                "10、散列表代码实现-Bloom Filter 布隆过滤器代码实现",
                "11、散列表代码实现-做题须知",
              ],
            },
            {
              weekname: "第 20 课 堆",
              dis: "Heap 的原理及实现",
              children: [
                "1、堆-堆的介绍",
                "2、堆-保持堆的性质",
                "3、堆-其他函数的实现",
                "4、堆-Heapify 过程",
                "5、堆-堆排序",
                "6、堆-排序算法时间下界",
                "7、堆-PriorityQueue 源码分析",
                "8、堆-索引堆",
                "9、堆-索引堆应用",
              ],
            },
          ],
        },
        {
          name: "第 21 课 ~ 第 24 课",
          weeks: [
            {
              weekname: "第 21 课 图形",
              dis: "点，线，面，到各种图形考点",
              children: [
                "1、图形-图形题简介",
                "2、图形-点在线段上",
                "3、图形-三点方向",
                "4、图形-点在直线上",
                "5、图形-点与面的关系",
                "6、图形-线段是否相交",
                "7、图形-点是否在多边形内",
                "8、图形-圆简介",
                "9、图形-方格与圆",
                "10、图形-圆与三角形",
                "11、图形-多边形考点",
                "12、图形-二分图",
                "13、图形-正方形",
                "14、图形-长方形",
                "15、图形-不平行于x轴怎么办",
                "16、图形-三角形和小结",
              ],
            },
            {
              weekname: "第 22 课 堆",
              dis: "PriorityQueue 的各种用法",
              children: [
                "1、堆-PriorityQueue 写法介绍",
                "2、堆-全排序 VS 局部排序",
                "3、堆-Arrays.sort 应用",
                "4、堆-Pair 和时间复杂度",
                "5、堆-类的应用——外排序",
                "6、堆-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 23 课 图",
              dis: "图的原理及实现方式",
              children: [
                "1、图-图",
                "2、图-图的表示",
                "3、图-深度优先搜索原理",
                "4、图-DFS 递归代码实现1",
                "5、图-DFS 递归代码实现2",
                "6、图-迭代代码实现",
                "7、图-DFS 复杂度分析",
                "8、图-广度优先搜索原理",
                "9、图-BFS 代码实现",
                "10、图-代码实现和 BFS 复杂度分析",
                "11、图-遍历应用和实战演练",
              ],
            },
            {
              weekname: "第 24 课 拓扑排序",
              dis: "图的原理及实现方式",
              children: [
                "1、拓扑排序-拓扑排序",
                "2、拓扑排序-DFS 实现拓扑排序",
                "3、拓扑排序-DFS 拓扑排序代码实现",
                "4、拓扑排序-BFS 实现拓扑排序",
                "5、拓扑排序-BFS 拓扑排序代码实现",
                "6、拓扑排序-拓扑排序复杂度",
              ],
            },
          ],
        },
        {
          name: "第 25 课 ~ 第 28 课",
          weeks: [
            {
              weekname: "第 25 课 并查集",
              dis: "Union Find 的原理及实现",
              children: [
                "1、并查集-Union Find 简介",
                "2、并查集-Quick Find 实现",
                "3、并查集-Quick Union 实现",
                "4、并查集-Union Find 两种优化方式",
                "5、并查集-Union Find 总结",
              ],
            },
            {
              weekname: "第 26 课 回溯法",
              dis: "回溯三大原始题型和实现题",
              children: [
                "1、回溯法-回溯概念",
                "2、回溯法-回溯三大题型——排列",
                "3、回溯法-回溯三大题型——子集",
                "4、回溯法-回溯三大题型——组合",
                "5、回溯法-模版和值传递",
                "6、回溯法-回溯法的实现题",
                "7、回溯法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 27 课 图",
              dis: "图的Flood Fill，BFS，匈牙利算法",
              children: [
                "1、图-图的基础回顾",
                "2、图-Flood Fill 的 DFS",
                "3、图-Flood Fill 的 BFS",
                "4、图-BFS 最终优化",
                "5、图-迷宫，棋盘怎么破",
                "6、图-双向广度搜索",
                "7、图-思考方式——转换图",
                "8、图-匹配",
                "9、图-匈牙利算法原理",
                "10、图-匈牙利算法实现",
                "11、图-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 28 课 最小生成树",
              dis: "Prim 和 Kruskal 算法",
              children: [
                "1、最小生成树-最小生成树介绍",
                "2、最小生成树-Prim 算法介绍",
                "3、最小生成树-边，图代码实现",
                "4、最小生成树-Prim 代码实现",
                "5、最小生成树-Kruskal 算法介绍",
                "6、最小生成树-Kruskal 代码实现",
              ],
            },
          ],
        },
        {
          name: "第 29 课 ~ 第 32 课",
          weeks: [
            {
              weekname: "第 29 课 最短路径",
              dis: "Dijkstra，Bellman-Ford，Floyd-Warshall",
              children: [
                "1、最短路径-最短路径介绍",
                "2、最短路径-Dijkstra 原理",
                "3、最短路径-Dijkstra 算法实现1",
                "4、最短路径-Dijkstra 算法实现2",
                "5、最短路径-Dijkstra 时间复杂度",
                "6、最短路径-Bellman-Ford 算法原理",
                "7、最短路径-Bellman-Ford 代码实现",
                "8、最短路径-Floyd-Warshall 算法介绍",
                "9、最短路径-图的总结",
              ],
            },
            {
              weekname: "第 30 课 树",
              dis: "树，二叉树，二叉搜索树",
              children: [
                "1、树-树的概念",
                "2、树-先序遍历",
                "3、树-中序遍历",
                "4、树-后序遍历",
                "5、树-层次遍历",
                "6、树-二叉树表示——链表形式",
                "7、树-二叉查找树",
                "8、树-二叉查找树的插入",
                "9、树-二叉查找树的查询",
                "10、树-二叉查找树的删除",
                "11、树-补充和小结",
              ],
            },
            {
              weekname: "第 31 课 树题型",
              dis: "树的遍历，常考题型",
              children: [
                "1、树-树的第一想法",
                "2、树-双 Pre 题目和解法",
                "3、树-Inorder 题目和解法",
                "4、树-Postorder 题目和解法",
                "5、树-Levelorder 题目和解法",
                "6、树-Preorder 和 Backtracking",
                "7、树-二叉搜索树",
                "8、树-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 32 课 字典树",
              dis: "Trie的知识和考点",
              children: [
                "1、字典树-Trie 介绍",
                "2、字典树-TrieNode",
                "3、字典树-add() 和 contains() 函数",
                "4、字典树-前缀树——startsWith() 函数",
                "5、字典树-字符匹配——search() 函数",
                "6、字典树-字典结合",
                "7、字典树-Leetcode 作业题和思维导图",
              ],
            },
          ],
        },
        {
          name: "第 33 课 ~ 第 36 课",
          weeks: [
            {
              weekname: "第 33 课 线段树和树状数组",
              dis: "线段树的实现方式，树状数组的原理及应用",
              children: [
                "1、线段树和树状数组-Segment Tree 和 Binary Index Tree",
                "2、线段树和树状数组-线段树原理",
                "3、线段树和树状数组-SegmentTreeNode 实现 Segment Tree",
                "4、线段树和树状数组-数组实现 Segment Tree",
                "5、线段树和树状数组-lazy 思想",
                "6、线段树和树状数组-Binary Index Tree 原理",
                "7、线段树和树状数组-实现 Binary Index Tree",
                "8、线段树和树状数组-BIT 和 ST 对比",
                "9、线段树和树状数组-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 34 课 AVL树",
              dis: "AVL树的源码和代码",
              children: [
                "1、AVL树-AVL树",
                "2、AVL树-AVL树实现",
                "3、AVL树-AVL 的 LL 和 RR",
                "4、AVL树-AVL 的 LL 和 RR 代码实现",
                "5、AVL树-AVL 的 LR 和 RL 代码实现",
                "6、AVL树-AVL的删除和包含",
              ],
            },
            {
              weekname: "第 35 课 红黑树",
              dis: "红黑树的原理和代码",
              children: [
                "1、红黑树-2-3树",
                "2、红黑树-2-3树与红黑树",
                "3、红黑树-基础代码",
                "4、红黑树-红黑树的加入",
                "5、红黑树-旋转，颜色反转颜色代码",
                "6、红黑树-插入代码实现",
                "7、红黑树-红黑树测试和总结",
                "8、红黑树-Java 中的红黑树——TreeMap",
                "9、红黑树-TreeMap 中的特有函数",
              ],
            },
            {
              weekname: "第 36 课 贪心算法",
              dis: "贪心的原理和应用",
              children: [
                "1、贪心算法-贪心算法——贪心思想",
                "2、贪心算法-贪心算法解法步骤",
                "3、贪心算法-贪心的局限性",
                "4、贪心算法-Leetcode 作业题和思维导图",
              ],
            },
          ],
        },
        {
          name: "第 37 课 ~ 第 40 课",
          weeks: [
            {
              weekname: "第 37 课 贪心算法",
              dis: "贪心的原理和应用",
              children: [
                "1、贪心算法-贪心算法——贪心思想",
                "2、贪心算法-贪心算法解法步骤",
                "3、贪心算法-贪心的局限性",
                "4、贪心算法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 38 课 设计",
              dis: "设计题技巧和总结",
              children: [
                "1、设计-设计介绍——10种数据结构",
                "2、设计-HashSet VS HashMap",
                "3、设计-ArrayList VS LinkedList",
                "4、设计-Stack VS Queue VS Deque",
                "5、设计-TreeSet VS TreeMap VS PriorityQueue",
                "6、设计-设置 Class",
                "7、设计-做题思想和做题流程总结",
                "8、设计-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 39 课 动态规划",
              dis: "如何一步步推导出递归，最难题型",
              children: [
                "1、动态规划-如何推到出动态规划",
                "2、动态规划-Backtracking - Memorization - DP（1）",
                "3、动态规划-Backtracking - Memorization - DP（2）",
                "4、动态规划-三种方法小结",
                "5、动态规划-状态和状态转移方程",
                "6、动态规划-动态规划做题思路",
                "7、动态规划-01背包问题",
                "8、动态规划-01背包问题分析及其优化",
                "9、动态规划-完全背包——两种解决方法",
                "10、动态规划-多重背包和混合背包",
                "11、动态规划-字符串问题",
                "12、动态规划-网格问题",
                "13、动态规划-匹配问题",
                "14、动态规划-follow up —— 打印中间过程",
                "15、动态规划-贪心算法和动态规划",
                "16、动态规划-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 40 课 简历修改指导",
              dis: "英文简历怎么写，如何自我修改简历",
              children: [
                "1、简历的组成",
                "2、LaTeX",
                "3、Contact & Education",
                "4、Experience & Project",
                "5、Skills & 注意事项",
                "6、如何充实内容",
                "7、面试",
              ],
            },
          ],
        },
      ],
      //北美SDE求职速成班
      usExerciseClass: [
        {
          name: "算法初级阶段",
          weeks: [
            {
              weekname: "第 1 课 算法，时间 & 空间复杂度",
              dis: "各种情况的时间复杂度和空间复杂度分析",
              children: [
                "1、算法，时间&空间复杂度-算法和数据结构",
                "2、算法，时间&空间复杂度-时间复杂度",
                "3、算法，时间&空间复杂度-空间复杂度",
              ],
            },
            {
              weekname: "第 2 课 算法必备基础",
              dis: "递归，迭代，分治",
              children: [
                "1、算法必备基础-数据量级",
                "2、算法必备基础-递归",
                "3、算法必备基础-递归的时间和空间复杂度",
                "4、算法必备基础-重复调用怎么办",
                "5、算法必备基础-迭代",
                "6、算法必备基础-分治法",
                "7、算法必备基础-特殊的时间和空间复杂度",
              ],
            },
            {
              weekname: "第 3 章 数组",
              dis: "数组的实现和Java源码分析",
              children: [
                "1、数组-数组介绍",
                "2、数组-实现数组 - 基本方法",
                "3、数组-实现数组 - 增加",
                "4、数组-实现数组 - 查找",
                "5、数组-实现数组 - 修改",
                "6、数组-实现数组 - 删除",
                "7、数组-实现数组 - 优化和测试",
                "8、数组-数组的时间复杂度",
                "9、数组-数组动态化 + Lazy 思想",
                "10、数组-均摊时间 VS 平均时间",
                "11、数组-Java 源码分析 - ArrayList",
                "12、数组-Java 源码分析 - add()",
                "13、数组-Java 源码分析 - contains & get & set",
                "14、数组-Java 源码分析 - remove()",
                "15、数组-Leetcode 相关题目",
              ],
            },
            {
              weekname: "第 4 课 位运算",
              dis: "位运算的考点和扩展延伸",
              children: [
                "1、位运算-二进制",
                "2、位运算-Java 八大基本类型",
                "3、位运算-位运算",
                "4、位运算-编码标准",
                "5、位运算-面试技巧1 - XOR",
                "6、位运算-面试技巧2 - n&(n - 1)",
                "7、位运算-面试技巧3 - n&1",
                "8、位运算-数据量过大怎么办",
                "9、位运算-BitMap 应用",
                "10、位运算-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 5 课 数学题",
              dis: "数学题考点讲解",
              children: [
                "1、数学题-数学题概括",
                "2、数学题-考察要点1 - 越界",
                "3、数学题-考察要点2 - 进位",
                "4、数学题-考察要点3 - 符号字母",
                "5、数学题-常考题型 - 开方（牛顿法）",
                "6、数学题-特殊题型和必背代码",
                "7、数学题-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 6 课 基于比较的排序算法",
              dis: "合并，快速，冒泡，选择，插入排序",
              children: [
                "1、基于比较的排序算法-排序算法",
                "2、基于比较的排序算法-冒泡排序",
                "3、基于比较的排序算法-排序算法的稳定性",
                "4、基于比较的排序算法-类中比较 Comparable 和 Comparator",
                "5、基于比较的排序算法-Leetcode 中的冒泡排序",
                "6、基于比较的排序算法-选择排序",
                "7、基于比较的排序算法-Leetcode 中的选择排序",
                "8、基于比较的排序算法-插入排序",
                "9、基于比较的排序算法-插入排序的二分优化",
                "10、基于比较的排序算法-Leetcode 中的插入排序",
                "11、基于比较的排序算法-合并排序",
                "12、基于比较的排序算法-Leetcode 中的合并排序",
                "13、基于比较的排序算法-快速排序",
                "14、基于比较的排序算法-Leetcode 中的快速排序",
              ],
            },
            {
              weekname: "第 7 课 非比较的排序算法",
              dis: "计数，桶排序，Java源码分析",
              children: [
                "1、非比较的排序算法和Java数组相关源码-计数排序",
                "2、非比较的排序算法和Java数组相关源码-Leetcode 中的计数排序",
                "3、非比较的排序算法和Java数组相关源码-桶排序",
                "4、非比较的排序算法和Java数组相关源码-Leetcode 中的桶排序",
                "5、非比较的排序算法和Java数组相关源码-排序算法总结和荷兰国旗问题",
                "6、非比较的排序算法和Java数组相关源码-Java 源码 Collections 集合",
                "7、非比较的排序算法和Java数组相关源码-Java 源码 Arrays 方法",
                "8、非比较的排序算法和Java数组相关源码-Java 源码 Arrays.sort 分析",
                "9、非比较的排序算法和Java数组相关源码-Arrays.sort 比较",
              ],
            },
            {
              weekname: "第 8 课 数组题",
              dis: "数组常见题型和补充算法",
              children: [
                "1、数组题-常考题型 1 - 排序算法补充",
                "2、数组题-常考题型 2 - 单向双指针",
                "3、数组题-扫描线算法",
                "4、数组题-子数组 - 进行中处理",
                "5、数组题-子数组 - 分段式处理",
                "6、数组题-双指针 - 双向双指针",
                "7、数组题-常考题型 3 - 二维数组",
                "8、数组题-二维矩阵 - 旋转变换",
                "9、数组题-常考题型4 - 实现题",
                "10、数组题-数学定理",
                "11、数组题-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 9 课 链表",
              dis: "链表的介绍和应用",
              children: [
                "1、链表-什么是链表",
                "2、链表-链表的插入",
                "3、链表-链表的删除",
                "4、链表-链表的更改",
                "5、链表-链表的查找",
                "6、链表-范型的更改",
                "7、链表-时间复杂度和测试",
                "8、链表-静态，双向，循环，双向循环链表",
                "9、链表-Java 源码 LinkedList - add 方法",
                "10、链表-Java 源码 LinkedList - remove 方法",
                "11、链表-Java 源码 LinkedList - get 和 set 方法",
                "12、链表-Java 源码接口 List",
                "13、链表-ArrayList VS LinkedList",
              ],
            },
            {
              weekname: "第 10 课 二分查找",
              dis: "二分查找三种写法区别",
              children: [
                "1、二分查找-二分查找",
                "2、二分查找-递归写法",
                "3、二分查找-迭代写法 1：left <= right",
                "4、二分查找-迭代写法 2：left < right",
                "5、二分查找-迭代写法 3：left + 1< right",
                "6、二分查找-Java 源码中的二分查找",
                "7、二分查找-三种方法应用",
                "8、二分查找-Rotate题型",
                "9、二分查找-Rotate题型 follow up",
                "10、二分查找-二维数组",
                "11、二分查找-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 11 课 链表",
              dis: "链表常考题型",
              children: [
                "1、链表-链表题型介绍",
                "2、链表-常考题型 1 - 基本操作",
                "3、链表-常考题型 2 - 反转",
                "4、链表-常考题型 3 - 环",
                "5、链表-常考题型 4 - 删除",
                "6、链表-链表的递归实现",
                "7、链表-综合练习",
                "8、链表-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 12 课 随机",
              dis: "等概率抽取法和蓄水池算法",
              children: [
                "1、随机-等概率抽取法",
                "2、随机-Leetcode 中的等概率抽取法",
                "3、随机-蓄水池抽样1 - 选取一个",
                "4、随机-蓄水池抽样2 - 选取多个",
                "5、随机-蓄水池算法3 - 代码实现",
                "6、随机-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 13 课 栈",
              dis: "栈的介绍和Java源码分析",
              children: [
                "1、栈-栈的介绍",
                "2、栈-顺序栈的简单实现",
                "3、栈-顺序栈的具体实现",
                "4、栈-链式栈的具体实现",
                "5、栈-Java 源码分析 Stack 和 Vector VS ArrayList",
                "6、栈-栈和递归",
                "7、栈-递归转化迭代",
                "8、栈-Leetcode 中的 Stack",
                "9、栈-题型讲解",
              ],
            },
            {
              weekname: "第 14 课 队列",
              dis: "队列的介绍和Java源码分析",
              children: [
                "1、队列-什么是队列",
                "2、队列-Deque Queue Stack",
                "3、队列-Java 源码分析 Queue Deque",
                "4、队列-链表实现队列代码实现",
                "5、队列-数组实现队列",
                "6、队列-循环队列介绍",
                "7、队列-循环队列的代码实现",
                "8、队列-Java 源码 ArrayDeque 分析",
                "9、队列-Leetcode 中的 Queue 和 Stack",
                "10、队列-队列的应用",
              ],
            },
            {
              weekname: "第 15 课 栈",
              dis: "栈常考题型分类划分",
              children: [
                "1、栈-常考题型1 - 平衡符号",
                "2、栈-常考题型2 - 压栈匹配1",
                "3、栈-常考题型2 - 压栈匹配2",
                "4、栈-常考题型3 - 表达式计算1",
                "5、栈-常考题型3 - 表达式计算2",
                "6、栈-常考题型4 - 迭代极值1",
                "7、栈-常考题型4 - 迭代极值2",
                "8、栈-Leetcode 400题作业，思维导图",
              ],
            },
            {
              weekname: "第 16 课 字符串",
              dis: "字符串常考题型分类划分",
              children: [
                "1、字符串-String 基本函数",
                "2、字符串-字符串函数时间复杂度",
                "3、字符串-正则表达式",
                "4、字符串-转义字符 & 通配符",
                "5、字符串-String VS StringBuilder VS StringBuffer",
                "6、字符串-常考题型1 - Anagram",
                "7、字符串-常考题型2 - Substring",
                "8、字符串-常考题型3 - Parentheses",
                "9、字符串-常考题型4 - Palindrome",
                "10、字符串-常考题型5 - Subsequence",
                "11、字符串-常考题型6 -实现题",
                "12、字符串-Leetcode 400题作业，思维导图",
              ],
            },
            {
              weekname: "第 17 课 面试技巧指导",
              dis: "面试时如何和面试官交流，做题如何表现",
              children: [
                "1、面试流程——实习全职",
                "2、面试内容和流程",
                "3、题意",
                "4、输入输出，命名",
                "5、做题注意事项",
                "6、复杂度 & Case",
              ],
            },
            {
              weekname: "第 18 课 散列表基础知识",
              dis: "HashTable的基础，HashMap，HashSet，HashTable",
              children: [
                "1、散列表基础知识-HashTable HashMap HashSet 简介",
                "2、散列表基础知识-直接寻址表和散列表",
                "3、散列表基础知识-散列函数",
                "4、散列表基础知识-链接法",
                "5、散列表基础知识-碰撞过多怎么办",
                "6、散列表基础知识-开放地址法",
                "7、散列表基础知识-再哈希法和小结",
              ],
            },
            {
              weekname: "第 19 课 散列表代码实现",
              dis: "hashCode，HashMap，HashSet等Java源码分析",
              children: [
                "1、散列表代码实现-hashCode 和 equals",
                "2、散列表代码实现-链接法代码",
                "3、散列表代码实现-开放定址法代码实现",
                "4、散列表代码实现-HashTable 源码分析",
                "5、散列表代码实现-HashMap 源码分析",
                "6、散列表代码实现-HashSet 源码分析",
                "7、散列表代码实现-LinkedHashMap 简介",
                "8、散列表代码实现-Leetcode 实战练习题",
                "9、散列表代码实现-Bloom Filter 布隆过滤器",
                "10、散列表代码实现-Bloom Filter 布隆过滤器代码实现",
                "11、散列表代码实现-做题须知",
              ],
            },
            {
              weekname: "第 20 课 堆",
              dis: "Heap 的原理及实现",
              children: [
                "1、堆-堆的介绍",
                "2、堆-保持堆的性质",
                "3、堆-其他函数的实现",
                "4、堆-Heapify 过程",
                "5、堆-堆排序",
                "6、堆-排序算法时间下界",
                "7、堆-PriorityQueue 源码分析",
                "8、堆-索引堆",
                "9、堆-索引堆应用",
              ],
            },
          ],
        },
        {
          name: "算法高级阶段",
          weeks: [
            {
              weekname: "第 21 课 图形",
              dis: "点，线，面，到各种图形考点",
              children: [
                "1、图形-图形题简介",
                "2、图形-点在线段上",
                "3、图形-三点方向",
                "4、图形-点在直线上",
                "5、图形-点与面的关系",
                "6、图形-线段是否相交",
                "7、图形-点是否在多边形内",
                "8、图形-圆简介",
                "9、图形-方格与圆",
                "10、图形-圆与三角形",
                "11、图形-多边形考点",
                "12、图形-二分图",
                "13、图形-正方形",
                "14、图形-长方形",
                "15、图形-不平行于x轴怎么办",
                "16、图形-三角形和小结",
              ],
            },
            {
              weekname: "第 22 课 堆",
              dis: "PriorityQueue 的各种用法",
              children: [
                "1、堆-PriorityQueue 写法介绍",
                "2、堆-全排序 VS 局部排序",
                "3、堆-Arrays.sort 应用",
                "4、堆-Pair 和时间复杂度",
                "5、堆-类的应用——外排序",
                "6、堆-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 23 课 图",
              dis: "图的原理及实现方式",
              children: [
                "1、图-图",
                "2、图-图的表示",
                "3、图-深度优先搜索原理",
                "4、图-DFS 递归代码实现1",
                "5、图-DFS 递归代码实现2",
                "6、图-迭代代码实现",
                "7、图-DFS 复杂度分析",
                "8、图-广度优先搜索原理",
                "9、图-BFS 代码实现",
                "10、图-代码实现和 BFS 复杂度分析",
                "11、图-遍历应用和实战演练",
              ],
            },
            {
              weekname: "第 24 课 拓扑排序",
              dis: "图的原理及实现方式",
              children: [
                "1、拓扑排序-拓扑排序",
                "2、拓扑排序-DFS 实现拓扑排序",
                "3、拓扑排序-DFS 拓扑排序代码实现",
                "4、拓扑排序-BFS 实现拓扑排序",
                "5、拓扑排序-BFS 拓扑排序代码实现",
                "6、拓扑排序-拓扑排序复杂度",
              ],
            },
            {
              weekname: "第 25 课 并查集",
              dis: "Union Find 的原理及实现",
              children: [
                "1、并查集-Union Find 简介",
                "2、并查集-Quick Find 实现",
                "3、并查集-Quick Union 实现",
                "4、并查集-Union Find 两种优化方式",
                "5、并查集-Union Find 总结",
              ],
            },
            {
              weekname: "第 26 课 回溯法",
              dis: "回溯三大原始题型和实现题",
              children: [
                "1、回溯法-回溯概念",
                "2、回溯法-回溯三大题型——排列",
                "3、回溯法-回溯三大题型——子集",
                "4、回溯法-回溯三大题型——组合",
                "5、回溯法-模版和值传递",
                "6、回溯法-回溯法的实现题",
                "7、回溯法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 27 课 图",
              dis: "图的Flood Fill，BFS，匈牙利算法",
              children: [
                "1、图-图的基础回顾",
                "2、图-Flood Fill 的 DFS",
                "3、图-Flood Fill 的 BFS",
                "4、图-BFS 最终优化",
                "5、图-迷宫，棋盘怎么破",
                "6、图-双向广度搜索",
                "7、图-思考方式——转换图",
                "8、图-匹配",
                "9、图-匈牙利算法原理",
                "10、图-匈牙利算法实现",
                "11、图-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 28 课 最小生成树",
              dis: "Prim 和 Kruskal 算法",
              children: [
                "1、最小生成树-最小生成树介绍",
                "2、最小生成树-Prim 算法介绍",
                "3、最小生成树-边，图代码实现",
                "4、最小生成树-Prim 代码实现",
                "5、最小生成树-Kruskal 算法介绍",
                "6、最小生成树-Kruskal 代码实现",
              ],
            },
            {
              weekname: "第 29 课 最短路径",
              dis: "Dijkstra，Bellman-Ford，Floyd-Warshall",
              children: [
                "1、最短路径-最短路径介绍",
                "2、最短路径-Dijkstra 原理",
                "3、最短路径-Dijkstra 算法实现1",
                "4、最短路径-Dijkstra 算法实现2",
                "5、最短路径-Dijkstra 时间复杂度",
                "6、最短路径-Bellman-Ford 算法原理",
                "7、最短路径-Bellman-Ford 代码实现",
                "8、最短路径-Floyd-Warshall 算法介绍",
                "9、最短路径-图的总结",
              ],
            },
            {
              weekname: "第 30 课 树",
              dis: "树，二叉树，二叉搜索树",
              children: [
                "1、树-树的概念",
                "2、树-先序遍历",
                "3、树-中序遍历",
                "4、树-后序遍历",
                "5、树-层次遍历",
                "6、树-二叉树表示——链表形式",
                "7、树-二叉查找树",
                "8、树-二叉查找树的插入",
                "9、树-二叉查找树的查询",
                "10、树-二叉查找树的删除",
                "11、树-补充和小结",
              ],
            },
            {
              weekname: "第 31 课 树题型",
              dis: "树的遍历，常考题型",
              children: [
                "1、树-树的第一想法",
                "2、树-双 Pre 题目和解法",
                "3、树-Inorder 题目和解法",
                "4、树-Postorder 题目和解法",
                "5、树-Levelorder 题目和解法",
                "6、树-Preorder 和 Backtracking",
                "7、树-二叉搜索树",
                "8、树-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 32 课 字典树",
              dis: "Trie的知识和考点",
              children: [
                "1、字典树-Trie 介绍",
                "2、字典树-TrieNode",
                "3、字典树-add() 和 contains() 函数",
                "4、字典树-前缀树——startsWith() 函数",
                "5、字典树-字符匹配——search() 函数",
                "6、字典树-字典结合",
                "7、字典树-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 33 课 线段树和树状数组",
              dis: "线段树的实现方式，树状数组的原理及应用",
              children: [
                "1、线段树和树状数组-Segment Tree 和 Binary Index Tree",
                "2、线段树和树状数组-线段树原理",
                "3、线段树和树状数组-SegmentTreeNode 实现 Segment Tree",
                "4、线段树和树状数组-数组实现 Segment Tree",
                "5、线段树和树状数组-lazy 思想",
                "6、线段树和树状数组-Binary Index Tree 原理",
                "7、线段树和树状数组-实现 Binary Index Tree",
                "8、线段树和树状数组-BIT 和 ST 对比",
                "9、线段树和树状数组-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 34 课 AVL树",
              dis: "AVL树的源码和代码",
              children: [
                "1、AVL树-AVL树",
                "2、AVL树-AVL树实现",
                "3、AVL树-AVL 的 LL 和 RR",
                "4、AVL树-AVL 的 LL 和 RR 代码实现",
                "5、AVL树-AVL 的 LR 和 RL 代码实现",
                "6、AVL树-AVL的删除和包含",
              ],
            },
            {
              weekname: "第 35 课 红黑树",
              dis: "红黑树的原理和代码",
              children: [
                "1、红黑树-2-3树",
                "2、红黑树-2-3树与红黑树",
                "3、红黑树-基础代码",
                "4、红黑树-红黑树的加入",
                "5、红黑树-旋转，颜色反转颜色代码",
                "6、红黑树-插入代码实现",
                "7、红黑树-红黑树测试和总结",
                "8、红黑树-Java 中的红黑树——TreeMap",
                "9、红黑树-TreeMap 中的特有函数",
              ],
            },
            {
              weekname: "第 36 课 贪心算法",
              dis: "贪心的原理和应用",
              children: [
                "1、贪心算法-贪心算法——贪心思想",
                "2、贪心算法-贪心算法解法步骤",
                "3、贪心算法-贪心的局限性",
                "4、贪心算法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 37 课 贪心算法",
              dis: "贪心的原理和应用",
              children: [
                "1、贪心算法-贪心算法——贪心思想",
                "2、贪心算法-贪心算法解法步骤",
                "3、贪心算法-贪心的局限性",
                "4、贪心算法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 38 课 设计",
              dis: "设计题技巧和总结",
              children: [
                "1、设计-设计介绍——10种数据结构",
                "2、设计-HashSet VS HashMap",
                "3、设计-ArrayList VS LinkedList",
                "4、设计-Stack VS Queue VS Deque",
                "5、设计-TreeSet VS TreeMap VS PriorityQueue",
                "6、设计-设置 Class",
                "7、设计-做题思想和做题流程总结",
                "8、设计-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 39 课 动态规划",
              dis: "如何一步步推导出递归，最难题型",
              children: [
                "1、动态规划-如何推到出动态规划",
                "2、动态规划-Backtracking - Memorization - DP（1）",
                "3、动态规划-Backtracking - Memorization - DP（2）",
                "4、动态规划-三种方法小结",
                "5、动态规划-状态和状态转移方程",
                "6、动态规划-动态规划做题思路",
                "7、动态规划-01背包问题",
                "8、动态规划-01背包问题分析及其优化",
                "9、动态规划-完全背包——两种解决方法",
                "10、动态规划-多重背包和混合背包",
                "11、动态规划-字符串问题",
                "12、动态规划-网格问题",
                "13、动态规划-匹配问题",
                "14、动态规划-follow up —— 打印中间过程",
                "15、动态规划-贪心算法和动态规划",
                "16、动态规划-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 40 课 简历修改指导",
              dis: "英文简历怎么写，如何自我修改简历",
              children: [
                "1、简历的组成",
                "2、LaTeX",
                "3、Contact & Education",
                "4、Experience & Project",
                "5、Skills & 注意事项",
                "6、如何充实内容",
                "7、面试",
              ],
            },
          ],
        },
        {
          name: "Gof 23种模式",
          weeks: [
            {
              weekname: "第 41 课 对象三要素",
              dis: "面向对象三大要素",
              children: [
                "1、对象三要素——封装",
                "2、对象三要素——继承",
                "3、组合关系和聚合关系",
                "4、关联关系和依赖关系",
                "5、实现和抽象",
                "6、对象三要素——多态",
              ],
            },
            {
              weekname: "第 42 课 UML",
              dis: "UML",
              children: [
                "1、UML介绍",
                "2、用例图，顺序图，活动图",
                "3、类图的表示",
                "4、类与类之间的关系",
                "5、类图实例",
                "6、类图实例代码",
              ],
            },
            {
              weekname: "第 43 课 面向对象五大原则（上）",
              dis: "面向对象五大原则，外加两大原则",
              children: [
                "1、面向对象五大原则",
                "2、开放封闭原则构建",
                "3、开放封闭原则代码实现",
                "4、依赖倒置原则介绍",
                "5、依赖倒置原则代码实现1",
                "6、依赖的三种方法",
                "7、依赖倒置原则总结",
                "8、单一职责原则代码实现",
                "9、单一职责原则总结",
              ],
            },
            {
              weekname: "第 44 课 面向对象五大原则（下）",
              dis: "面向对象五大原则，外加两大原则",
              children: [
                "1、接口隔离原则",
                "2、接口隔离原则代码实现",
                "3、接口隔离原则总结",
                "4、里氏替换原则",
                "5、里氏替换原则代码实现",
                "6、里氏替换原则总结",
                "7、迪米特原则",
                "8、合成复用原则",
                "9、面向对象原则总结",
              ],
            },
            {
              weekname: "第 45 课 Gof 23种设计模式（1）",
              dis: "工厂模式，建造者模式",
              children: [
                "1、简单工厂模式",
                "2、工厂方法模式",
                "3、抽象工厂模式",
                "4、建造者模式代码",
                "5、建造者模式总结",
              ],
            },
            {
              weekname: "第 46 课 Gof 23种设计模式（2）",
              dis: "单例模式",
              children: [
                "1、单例模式",
                "2、八种写法——饿汉式2种",
                "3、八种写法——懒汉式+多线程Debug",
                "4、八种写法——懒汉式+同步2种",
                "5、八种写法——双重检查",
                "6、八种写法——静态内部类",
                "7、八种写法——枚举",
                "8、单例模式总结",
              ],
            },
            {
              weekname: "第 47 课 Gof 23种设计模式（3）",
              dis: "原型模式，适配器模式",
              children: [
                "1、原型模式",
                "2、深克隆 VS 浅克隆和其他考点",
                "3、原型模式总结",
                "4、适配器模式——类",
                "5、适配器模式——对象",
              ],
            },
            {
              weekname: "第 48 课 Gof 23种设计模式（4）",
              dis: "外观模式，装饰者模式，组合模式，享元模式",
              children: [
                "1、外观模式",
                "2、装饰者模式",
                "3、组合模式",
                "4、享元模式",
              ],
            },
            {
              weekname: "第 49 课 Gof 23种设计模式（5）",
              dis: "桥接模式，代理模式，迭代器模式，解释器模式",
              children: [
                "1、桥接模式",
                "2、代理模式",
                "3、迭代器模式",
                "4、解释器模式",
              ],
            },
            {
              weekname: "第 50 课 Gof 23种设计模式（6）",
              dis: "访问者模式，中介者模式，备忘录模式，观察者模式",
              children: [
                "1、访问者模式",
                "2、中介者模式",
                "3、备忘录模式",
                "4、观察者模式",
              ],
            },
            {
              weekname: "第 51 课 Gof 23种设计模式（7）",
              dis: "命令模式，责任链模式，状态模式，策略模式，模板方法模式",
              children: [
                "1、命令模式",
                "2、责任链模式",
                "3、状态模式",
                "4、策略模式",
                "5、模板方法模式",
              ],
            },
            {
              weekname: "第 52 课 Parking Lot 停车场",
              dis: "Parking Lot 停车场",
              children: [
                "1、OOD万能解法",
                "2、OOD加分项1",
                "3、OOD加分项2",
                "4、Method的表达",
                "5、OOD加分项3",
                "5、面试总结",
              ],
            },
            {
              weekname: "第 53 课 Elevator 电梯",
              dis: "Elevator 电梯",
              children: [
                "1、三步解法1",
                "2、三步万能解法2",
                "3、4种电梯算法",
                "4、三步万能解法3",
              ],
            },
            {
              weekname: "第 54 课 Amazon Locker 亚马逊快递柜",
              dis: "Amazon Locker 亚马逊快递柜",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
                "3、三步万能解法3",
              ],
            },
            {
              weekname: "第 55 课 Vending Machine 自动售货机",
              dis: "Vending Machine 自动售货机",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
              ],
            },
            {
              weekname: "第 56 课 Restaurant Reservation System 餐厅预订系统",
              dis: "Restaurant Reservation System 餐厅预订系统",
              children: [
                "1、预定类讲解1",
                "2、预定类讲解2",
                "3、预定类讲解3",
              ],
            },
            {
              weekname: "第 57 课 Hotel Reservation System 酒店预定系统",
              dis: "Hotel Reservation System 酒店预定系统",
              children: [
                "1、Hotel Reservation",
              ],
            },
            {
              weekname: "第 58 课 Movie Ticket Booking System 电影票订票系统",
              dis: "Movie Ticket Booking System 电影票订票系统",
              children: [
                "1、Movie Ticket 1",
                "2、Movie Ticket 2",
              ],
            },
            {
              weekname: "第 59 课 Tic Tac Toe 井字游戏",
              dis: "Tic Tac Toe 井字游戏",
              children: [
                "1、TicTacToe",
              ],
            },
            {
              weekname: "第 60 课 Chess 国际象棋",
              dis: "Chess 国际象棋",
              children: [
                "1、Chess 1",
                "2、Chess 2",
              ],
            },
            {
              weekname: "第 61 课 Blackjack 21点纸牌游戏",
              dis: "Blackjack 21点纸牌游戏",
              children: [
                "1、Blackjack",
              ],
            },
            {
              weekname: "第 62 课 ATM ATM机",
              dis: "ATM ATM机",
              children: [
                "1、ATM",
              ],
            },
            {
              weekname: "第 63 课  Online Book Reader System 在线图书系统",
              dis: "Online Book Reader System 在线图书系统",
              children: [
                "1、Online Book Reader System",
              ],
            },
          ],
        },
        {
          name: "OOD 实战案例",
          weeks: [
            {
              weekname: "第 52 课 Parking Lot 停车场",
              dis: "Parking Lot 停车场",
              children: [
                "1、OOD万能解法",
                "2、OOD加分项1",
                "3、OOD加分项2",
                "4、Method的表达",
                "5、OOD加分项3",
                "5、面试总结",
              ],
            },
            {
              weekname: "第 53 课 Elevator 电梯",
              dis: "Elevator 电梯",
              children: [
                "1、三步解法1",
                "2、三步万能解法2",
                "3、4种电梯算法",
                "4、三步万能解法3",
              ],
            },
            {
              weekname: "第 54 课 Amazon Locker 亚马逊快递柜",
              dis: "Amazon Locker 亚马逊快递柜",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
                "3、三步万能解法3",
              ],
            },
            {
              weekname: "第 55 课 Vending Machine 自动售货机",
              dis: "Vending Machine 自动售货机",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
              ],
            },
            {
              weekname: "第 56 课 Restaurant Reservation System 餐厅预订系统",
              dis: "Restaurant Reservation System 餐厅预订系统",
              children: [
                "1、预定类讲解1",
                "2、预定类讲解2",
                "3、预定类讲解3",
              ],
            },
            {
              weekname: "第 57 课 Hotel Reservation System 酒店预定系统",
              dis: "Hotel Reservation System 酒店预定系统",
              children: [
                "1、Hotel Reservation",
              ],
            },
            {
              weekname: "第 58 课 Movie Ticket Booking System 电影票订票系统",
              dis: "Movie Ticket Booking System 电影票订票系统",
              children: [
                "1、Movie Ticket 1",
                "2、Movie Ticket 2",
              ],
            },
            {
              weekname: "第 59 课 Tic Tac Toe 井字游戏",
              dis: "Tic Tac Toe 井字游戏",
              children: [
                "1、TicTacToe",
              ],
            },
            {
              weekname: "第 60 课 Chess 国际象棋",
              dis: "Chess 国际象棋",
              children: [
                "1、Chess 1",
                "2、Chess 2",
              ],
            },
            {
              weekname: "第 61 课 Blackjack 21点纸牌游戏",
              dis: "Blackjack 21点纸牌游戏",
              children: [
                "1、Blackjack",
              ],
            },
            {
              weekname: "第 62 课 ATM ATM机",
              dis: "ATM ATM机",
              children: [
                "1、ATM",
              ],
            },
            {
              weekname: "第 63 课  Online Book Reader System 在线图书系统",
              dis: "Online Book Reader System 在线图书系统",
              children: [
                "1、Online Book Reader System",
              ],
            },
          ],
        },
        {
          name: "System Design",
          weeks: [
            {
              weekname: "第 64 课 System Design",
              dis: "System Design",
              children: [
                "System Design，九月初开放目录",
              ],
            },

          ],
        },
      ],
      //北美SDE求职旗舰班
      VIPClass: [
        {
          name: "算法初级阶段",
          weeks: [
            {
              weekname: "第 1 课 算法，时间 & 空间复杂度",
              dis: "各种情况的时间复杂度和空间复杂度分析",
              children: [
                "1、算法，时间&空间复杂度-算法和数据结构",
                "2、算法，时间&空间复杂度-时间复杂度",
                "3、算法，时间&空间复杂度-空间复杂度",
              ],
            },
            {
              weekname: "第 2 课 算法必备基础",
              dis: "递归，迭代，分治",
              children: [
                "1、算法必备基础-数据量级",
                "2、算法必备基础-递归",
                "3、算法必备基础-递归的时间和空间复杂度",
                "4、算法必备基础-重复调用怎么办",
                "5、算法必备基础-迭代",
                "6、算法必备基础-分治法",
                "7、算法必备基础-特殊的时间和空间复杂度",
              ],
            },
            {
              weekname: "第 3 章 数组",
              dis: "数组的实现和Java源码分析",
              children: [
                "1、数组-数组介绍",
                "2、数组-实现数组 - 基本方法",
                "3、数组-实现数组 - 增加",
                "4、数组-实现数组 - 查找",
                "5、数组-实现数组 - 修改",
                "6、数组-实现数组 - 删除",
                "7、数组-实现数组 - 优化和测试",
                "8、数组-数组的时间复杂度",
                "9、数组-数组动态化 + Lazy 思想",
                "10、数组-均摊时间 VS 平均时间",
                "11、数组-Java 源码分析 - ArrayList",
                "12、数组-Java 源码分析 - add()",
                "13、数组-Java 源码分析 - contains & get & set",
                "14、数组-Java 源码分析 - remove()",
                "15、数组-Leetcode 相关题目",
              ],
            },
            {
              weekname: "第 4 课 位运算",
              dis: "位运算的考点和扩展延伸",
              children: [
                "1、位运算-二进制",
                "2、位运算-Java 八大基本类型",
                "3、位运算-位运算",
                "4、位运算-编码标准",
                "5、位运算-面试技巧1 - XOR",
                "6、位运算-面试技巧2 - n&(n - 1)",
                "7、位运算-面试技巧3 - n&1",
                "8、位运算-数据量过大怎么办",
                "9、位运算-BitMap 应用",
                "10、位运算-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 5 课 数学题",
              dis: "数学题考点讲解",
              children: [
                "1、数学题-数学题概括",
                "2、数学题-考察要点1 - 越界",
                "3、数学题-考察要点2 - 进位",
                "4、数学题-考察要点3 - 符号字母",
                "5、数学题-常考题型 - 开方（牛顿法）",
                "6、数学题-特殊题型和必背代码",
                "7、数学题-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 6 课 基于比较的排序算法",
              dis: "合并，快速，冒泡，选择，插入排序",
              children: [
                "1、基于比较的排序算法-排序算法",
                "2、基于比较的排序算法-冒泡排序",
                "3、基于比较的排序算法-排序算法的稳定性",
                "4、基于比较的排序算法-类中比较 Comparable 和 Comparator",
                "5、基于比较的排序算法-Leetcode 中的冒泡排序",
                "6、基于比较的排序算法-选择排序",
                "7、基于比较的排序算法-Leetcode 中的选择排序",
                "8、基于比较的排序算法-插入排序",
                "9、基于比较的排序算法-插入排序的二分优化",
                "10、基于比较的排序算法-Leetcode 中的插入排序",
                "11、基于比较的排序算法-合并排序",
                "12、基于比较的排序算法-Leetcode 中的合并排序",
                "13、基于比较的排序算法-快速排序",
                "14、基于比较的排序算法-Leetcode 中的快速排序",
              ],
            },
            {
              weekname: "第 7 课 非比较的排序算法",
              dis: "计数，桶排序，Java源码分析",
              children: [
                "1、非比较的排序算法和Java数组相关源码-计数排序",
                "2、非比较的排序算法和Java数组相关源码-Leetcode 中的计数排序",
                "3、非比较的排序算法和Java数组相关源码-桶排序",
                "4、非比较的排序算法和Java数组相关源码-Leetcode 中的桶排序",
                "5、非比较的排序算法和Java数组相关源码-排序算法总结和荷兰国旗问题",
                "6、非比较的排序算法和Java数组相关源码-Java 源码 Collections 集合",
                "7、非比较的排序算法和Java数组相关源码-Java 源码 Arrays 方法",
                "8、非比较的排序算法和Java数组相关源码-Java 源码 Arrays.sort 分析",
                "9、非比较的排序算法和Java数组相关源码-Arrays.sort 比较",
              ],
            },
            {
              weekname: "第 8 课 数组题",
              dis: "数组常见题型和补充算法",
              children: [
                "1、数组题-常考题型 1 - 排序算法补充",
                "2、数组题-常考题型 2 - 单向双指针",
                "3、数组题-扫描线算法",
                "4、数组题-子数组 - 进行中处理",
                "5、数组题-子数组 - 分段式处理",
                "6、数组题-双指针 - 双向双指针",
                "7、数组题-常考题型 3 - 二维数组",
                "8、数组题-二维矩阵 - 旋转变换",
                "9、数组题-常考题型4 - 实现题",
                "10、数组题-数学定理",
                "11、数组题-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 9 课 链表",
              dis: "链表的介绍和应用",
              children: [
                "1、链表-什么是链表",
                "2、链表-链表的插入",
                "3、链表-链表的删除",
                "4、链表-链表的更改",
                "5、链表-链表的查找",
                "6、链表-范型的更改",
                "7、链表-时间复杂度和测试",
                "8、链表-静态，双向，循环，双向循环链表",
                "9、链表-Java 源码 LinkedList - add 方法",
                "10、链表-Java 源码 LinkedList - remove 方法",
                "11、链表-Java 源码 LinkedList - get 和 set 方法",
                "12、链表-Java 源码接口 List",
                "13、链表-ArrayList VS LinkedList",
              ],
            },
            {
              weekname: "第 10 课 二分查找",
              dis: "二分查找三种写法区别",
              children: [
                "1、二分查找-二分查找",
                "2、二分查找-递归写法",
                "3、二分查找-迭代写法 1：left <= right",
                "4、二分查找-迭代写法 2：left < right",
                "5、二分查找-迭代写法 3：left + 1< right",
                "6、二分查找-Java 源码中的二分查找",
                "7、二分查找-三种方法应用",
                "8、二分查找-Rotate题型",
                "9、二分查找-Rotate题型 follow up",
                "10、二分查找-二维数组",
                "11、二分查找-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 11 课 链表",
              dis: "链表常考题型",
              children: [
                "1、链表-链表题型介绍",
                "2、链表-常考题型 1 - 基本操作",
                "3、链表-常考题型 2 - 反转",
                "4、链表-常考题型 3 - 环",
                "5、链表-常考题型 4 - 删除",
                "6、链表-链表的递归实现",
                "7、链表-综合练习",
                "8、链表-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 12 课 随机",
              dis: "等概率抽取法和蓄水池算法",
              children: [
                "1、随机-等概率抽取法",
                "2、随机-Leetcode 中的等概率抽取法",
                "3、随机-蓄水池抽样1 - 选取一个",
                "4、随机-蓄水池抽样2 - 选取多个",
                "5、随机-蓄水池算法3 - 代码实现",
                "6、随机-Leetcode作业题和思维导图",
              ],
            },
            {
              weekname: "第 13 课 栈",
              dis: "栈的介绍和Java源码分析",
              children: [
                "1、栈-栈的介绍",
                "2、栈-顺序栈的简单实现",
                "3、栈-顺序栈的具体实现",
                "4、栈-链式栈的具体实现",
                "5、栈-Java 源码分析 Stack 和 Vector VS ArrayList",
                "6、栈-栈和递归",
                "7、栈-递归转化迭代",
                "8、栈-Leetcode 中的 Stack",
                "9、栈-题型讲解",
              ],
            },
            {
              weekname: "第 14 课 队列",
              dis: "队列的介绍和Java源码分析",
              children: [
                "1、队列-什么是队列",
                "2、队列-Deque Queue Stack",
                "3、队列-Java 源码分析 Queue Deque",
                "4、队列-链表实现队列代码实现",
                "5、队列-数组实现队列",
                "6、队列-循环队列介绍",
                "7、队列-循环队列的代码实现",
                "8、队列-Java 源码 ArrayDeque 分析",
                "9、队列-Leetcode 中的 Queue 和 Stack",
                "10、队列-队列的应用",
              ],
            },
            {
              weekname: "第 15 课 栈",
              dis: "栈常考题型分类划分",
              children: [
                "1、栈-常考题型1 - 平衡符号",
                "2、栈-常考题型2 - 压栈匹配1",
                "3、栈-常考题型2 - 压栈匹配2",
                "4、栈-常考题型3 - 表达式计算1",
                "5、栈-常考题型3 - 表达式计算2",
                "6、栈-常考题型4 - 迭代极值1",
                "7、栈-常考题型4 - 迭代极值2",
                "8、栈-Leetcode 400题作业，思维导图",
              ],
            },
            {
              weekname: "第 16 课 字符串",
              dis: "字符串常考题型分类划分",
              children: [
                "1、字符串-String 基本函数",
                "2、字符串-字符串函数时间复杂度",
                "3、字符串-正则表达式",
                "4、字符串-转义字符 & 通配符",
                "5、字符串-String VS StringBuilder VS StringBuffer",
                "6、字符串-常考题型1 - Anagram",
                "7、字符串-常考题型2 - Substring",
                "8、字符串-常考题型3 - Parentheses",
                "9、字符串-常考题型4 - Palindrome",
                "10、字符串-常考题型5 - Subsequence",
                "11、字符串-常考题型6 -实现题",
                "12、字符串-Leetcode 400题作业，思维导图",
              ],
            },
            {
              weekname: "第 17 课 面试技巧指导",
              dis: "面试时如何和面试官交流，做题如何表现",
              children: [
                "1、面试流程——实习全职",
                "2、面试内容和流程",
                "3、题意",
                "4、输入输出，命名",
                "5、做题注意事项",
                "6、复杂度 & Case",
              ],
            },
            {
              weekname: "第 18 课 散列表基础知识",
              dis: "HashTable的基础，HashMap，HashSet，HashTable",
              children: [
                "1、散列表基础知识-HashTable HashMap HashSet 简介",
                "2、散列表基础知识-直接寻址表和散列表",
                "3、散列表基础知识-散列函数",
                "4、散列表基础知识-链接法",
                "5、散列表基础知识-碰撞过多怎么办",
                "6、散列表基础知识-开放地址法",
                "7、散列表基础知识-再哈希法和小结",
              ],
            },
            {
              weekname: "第 19 课 散列表代码实现",
              dis: "hashCode，HashMap，HashSet等Java源码分析",
              children: [
                "1、散列表代码实现-hashCode 和 equals",
                "2、散列表代码实现-链接法代码",
                "3、散列表代码实现-开放定址法代码实现",
                "4、散列表代码实现-HashTable 源码分析",
                "5、散列表代码实现-HashMap 源码分析",
                "6、散列表代码实现-HashSet 源码分析",
                "7、散列表代码实现-LinkedHashMap 简介",
                "8、散列表代码实现-Leetcode 实战练习题",
                "9、散列表代码实现-Bloom Filter 布隆过滤器",
                "10、散列表代码实现-Bloom Filter 布隆过滤器代码实现",
                "11、散列表代码实现-做题须知",
              ],
            },
            {
              weekname: "第 20 课 堆",
              dis: "Heap 的原理及实现",
              children: [
                "1、堆-堆的介绍",
                "2、堆-保持堆的性质",
                "3、堆-其他函数的实现",
                "4、堆-Heapify 过程",
                "5、堆-堆排序",
                "6、堆-排序算法时间下界",
                "7、堆-PriorityQueue 源码分析",
                "8、堆-索引堆",
                "9、堆-索引堆应用",
              ],
            },
          ],
        },
        {
          name: "算法高级阶段",
          weeks: [
            {
              weekname: "第 21 课 图形",
              dis: "点，线，面，到各种图形考点",
              children: [
                "1、图形-图形题简介",
                "2、图形-点在线段上",
                "3、图形-三点方向",
                "4、图形-点在直线上",
                "5、图形-点与面的关系",
                "6、图形-线段是否相交",
                "7、图形-点是否在多边形内",
                "8、图形-圆简介",
                "9、图形-方格与圆",
                "10、图形-圆与三角形",
                "11、图形-多边形考点",
                "12、图形-二分图",
                "13、图形-正方形",
                "14、图形-长方形",
                "15、图形-不平行于x轴怎么办",
                "16、图形-三角形和小结",
              ],
            },
            {
              weekname: "第 22 课 堆",
              dis: "PriorityQueue 的各种用法",
              children: [
                "1、堆-PriorityQueue 写法介绍",
                "2、堆-全排序 VS 局部排序",
                "3、堆-Arrays.sort 应用",
                "4、堆-Pair 和时间复杂度",
                "5、堆-类的应用——外排序",
                "6、堆-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 23 课 图",
              dis: "图的原理及实现方式",
              children: [
                "1、图-图",
                "2、图-图的表示",
                "3、图-深度优先搜索原理",
                "4、图-DFS 递归代码实现1",
                "5、图-DFS 递归代码实现2",
                "6、图-迭代代码实现",
                "7、图-DFS 复杂度分析",
                "8、图-广度优先搜索原理",
                "9、图-BFS 代码实现",
                "10、图-代码实现和 BFS 复杂度分析",
                "11、图-遍历应用和实战演练",
              ],
            },
            {
              weekname: "第 24 课 拓扑排序",
              dis: "图的原理及实现方式",
              children: [
                "1、拓扑排序-拓扑排序",
                "2、拓扑排序-DFS 实现拓扑排序",
                "3、拓扑排序-DFS 拓扑排序代码实现",
                "4、拓扑排序-BFS 实现拓扑排序",
                "5、拓扑排序-BFS 拓扑排序代码实现",
                "6、拓扑排序-拓扑排序复杂度",
              ],
            },
            {
              weekname: "第 25 课 并查集",
              dis: "Union Find 的原理及实现",
              children: [
                "1、并查集-Union Find 简介",
                "2、并查集-Quick Find 实现",
                "3、并查集-Quick Union 实现",
                "4、并查集-Union Find 两种优化方式",
                "5、并查集-Union Find 总结",
              ],
            },
            {
              weekname: "第 26 课 回溯法",
              dis: "回溯三大原始题型和实现题",
              children: [
                "1、回溯法-回溯概念",
                "2、回溯法-回溯三大题型——排列",
                "3、回溯法-回溯三大题型——子集",
                "4、回溯法-回溯三大题型——组合",
                "5、回溯法-模版和值传递",
                "6、回溯法-回溯法的实现题",
                "7、回溯法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 27 课 图",
              dis: "图的Flood Fill，BFS，匈牙利算法",
              children: [
                "1、图-图的基础回顾",
                "2、图-Flood Fill 的 DFS",
                "3、图-Flood Fill 的 BFS",
                "4、图-BFS 最终优化",
                "5、图-迷宫，棋盘怎么破",
                "6、图-双向广度搜索",
                "7、图-思考方式——转换图",
                "8、图-匹配",
                "9、图-匈牙利算法原理",
                "10、图-匈牙利算法实现",
                "11、图-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 28 课 最小生成树",
              dis: "Prim 和 Kruskal 算法",
              children: [
                "1、最小生成树-最小生成树介绍",
                "2、最小生成树-Prim 算法介绍",
                "3、最小生成树-边，图代码实现",
                "4、最小生成树-Prim 代码实现",
                "5、最小生成树-Kruskal 算法介绍",
                "6、最小生成树-Kruskal 代码实现",
              ],
            },
            {
              weekname: "第 29 课 最短路径",
              dis: "Dijkstra，Bellman-Ford，Floyd-Warshall",
              children: [
                "1、最短路径-最短路径介绍",
                "2、最短路径-Dijkstra 原理",
                "3、最短路径-Dijkstra 算法实现1",
                "4、最短路径-Dijkstra 算法实现2",
                "5、最短路径-Dijkstra 时间复杂度",
                "6、最短路径-Bellman-Ford 算法原理",
                "7、最短路径-Bellman-Ford 代码实现",
                "8、最短路径-Floyd-Warshall 算法介绍",
                "9、最短路径-图的总结",
              ],
            },
            {
              weekname: "第 30 课 树",
              dis: "树，二叉树，二叉搜索树",
              children: [
                "1、树-树的概念",
                "2、树-先序遍历",
                "3、树-中序遍历",
                "4、树-后序遍历",
                "5、树-层次遍历",
                "6、树-二叉树表示——链表形式",
                "7、树-二叉查找树",
                "8、树-二叉查找树的插入",
                "9、树-二叉查找树的查询",
                "10、树-二叉查找树的删除",
                "11、树-补充和小结",
              ],
            },
            {
              weekname: "第 31 课 树题型",
              dis: "树的遍历，常考题型",
              children: [
                "1、树-树的第一想法",
                "2、树-双 Pre 题目和解法",
                "3、树-Inorder 题目和解法",
                "4、树-Postorder 题目和解法",
                "5、树-Levelorder 题目和解法",
                "6、树-Preorder 和 Backtracking",
                "7、树-二叉搜索树",
                "8、树-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 32 课 字典树",
              dis: "Trie的知识和考点",
              children: [
                "1、字典树-Trie 介绍",
                "2、字典树-TrieNode",
                "3、字典树-add() 和 contains() 函数",
                "4、字典树-前缀树——startsWith() 函数",
                "5、字典树-字符匹配——search() 函数",
                "6、字典树-字典结合",
                "7、字典树-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 33 课 线段树和树状数组",
              dis: "线段树的实现方式，树状数组的原理及应用",
              children: [
                "1、线段树和树状数组-Segment Tree 和 Binary Index Tree",
                "2、线段树和树状数组-线段树原理",
                "3、线段树和树状数组-SegmentTreeNode 实现 Segment Tree",
                "4、线段树和树状数组-数组实现 Segment Tree",
                "5、线段树和树状数组-lazy 思想",
                "6、线段树和树状数组-Binary Index Tree 原理",
                "7、线段树和树状数组-实现 Binary Index Tree",
                "8、线段树和树状数组-BIT 和 ST 对比",
                "9、线段树和树状数组-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 34 课 AVL树",
              dis: "AVL树的源码和代码",
              children: [
                "1、AVL树-AVL树",
                "2、AVL树-AVL树实现",
                "3、AVL树-AVL 的 LL 和 RR",
                "4、AVL树-AVL 的 LL 和 RR 代码实现",
                "5、AVL树-AVL 的 LR 和 RL 代码实现",
                "6、AVL树-AVL的删除和包含",
              ],
            },
            {
              weekname: "第 35 课 红黑树",
              dis: "红黑树的原理和代码",
              children: [
                "1、红黑树-2-3树",
                "2、红黑树-2-3树与红黑树",
                "3、红黑树-基础代码",
                "4、红黑树-红黑树的加入",
                "5、红黑树-旋转，颜色反转颜色代码",
                "6、红黑树-插入代码实现",
                "7、红黑树-红黑树测试和总结",
                "8、红黑树-Java 中的红黑树——TreeMap",
                "9、红黑树-TreeMap 中的特有函数",
              ],
            },
            {
              weekname: "第 36 课 贪心算法",
              dis: "贪心的原理和应用",
              children: [
                "1、贪心算法-贪心算法——贪心思想",
                "2、贪心算法-贪心算法解法步骤",
                "3、贪心算法-贪心的局限性",
                "4、贪心算法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 37 课 贪心算法",
              dis: "贪心的原理和应用",
              children: [
                "1、贪心算法-贪心算法——贪心思想",
                "2、贪心算法-贪心算法解法步骤",
                "3、贪心算法-贪心的局限性",
                "4、贪心算法-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 38 课 设计",
              dis: "设计题技巧和总结",
              children: [
                "1、设计-设计介绍——10种数据结构",
                "2、设计-HashSet VS HashMap",
                "3、设计-ArrayList VS LinkedList",
                "4、设计-Stack VS Queue VS Deque",
                "5、设计-TreeSet VS TreeMap VS PriorityQueue",
                "6、设计-设置 Class",
                "7、设计-做题思想和做题流程总结",
                "8、设计-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 39 课 动态规划",
              dis: "如何一步步推导出递归，最难题型",
              children: [
                "1、动态规划-如何推到出动态规划",
                "2、动态规划-Backtracking - Memorization - DP（1）",
                "3、动态规划-Backtracking - Memorization - DP（2）",
                "4、动态规划-三种方法小结",
                "5、动态规划-状态和状态转移方程",
                "6、动态规划-动态规划做题思路",
                "7、动态规划-01背包问题",
                "8、动态规划-01背包问题分析及其优化",
                "9、动态规划-完全背包——两种解决方法",
                "10、动态规划-多重背包和混合背包",
                "11、动态规划-字符串问题",
                "12、动态规划-网格问题",
                "13、动态规划-匹配问题",
                "14、动态规划-follow up —— 打印中间过程",
                "15、动态规划-贪心算法和动态规划",
                "16、动态规划-Leetcode 作业题和思维导图",
              ],
            },
            {
              weekname: "第 40 课 简历修改指导",
              dis: "英文简历怎么写，如何自我修改简历",
              children: [
                "1、简历的组成",
                "2、LaTeX",
                "3、Contact & Education",
                "4、Experience & Project",
                "5、Skills & 注意事项",
                "6、如何充实内容",
                "7、面试",
              ],
            },
          ],
        },
        {
          name: "Gof 23种模式",
          weeks: [
            {
              weekname: "第 41 课 对象三要素",
              dis: "面向对象三大要素",
              children: [
                "1、对象三要素——封装",
                "2、对象三要素——继承",
                "3、组合关系和聚合关系",
                "4、关联关系和依赖关系",
                "5、实现和抽象",
                "6、对象三要素——多态",
              ],
            },
            {
              weekname: "第 42 课 UML",
              dis: "UML",
              children: [
                "1、UML介绍",
                "2、用例图，顺序图，活动图",
                "3、类图的表示",
                "4、类与类之间的关系",
                "5、类图实例",
                "6、类图实例代码",
              ],
            },
            {
              weekname: "第 43 课 面向对象五大原则（上）",
              dis: "面向对象五大原则，外加两大原则",
              children: [
                "1、面向对象五大原则",
                "2、开放封闭原则构建",
                "3、开放封闭原则代码实现",
                "4、依赖倒置原则介绍",
                "5、依赖倒置原则代码实现1",
                "6、依赖的三种方法",
                "7、依赖倒置原则总结",
                "8、单一职责原则代码实现",
                "9、单一职责原则总结",
              ],
            },
            {
              weekname: "第 44 课 面向对象五大原则（下）",
              dis: "面向对象五大原则，外加两大原则",
              children: [
                "1、接口隔离原则",
                "2、接口隔离原则代码实现",
                "3、接口隔离原则总结",
                "4、里氏替换原则",
                "5、里氏替换原则代码实现",
                "6、里氏替换原则总结",
                "7、迪米特原则",
                "8、合成复用原则",
                "9、面向对象原则总结",
              ],
            },
            {
              weekname: "第 45 课 Gof 23种设计模式（1）",
              dis: "工厂模式，建造者模式",
              children: [
                "1、简单工厂模式",
                "2、工厂方法模式",
                "3、抽象工厂模式",
                "4、建造者模式代码",
                "5、建造者模式总结",
              ],
            },
            {
              weekname: "第 46 课 Gof 23种设计模式（2）",
              dis: "单例模式",
              children: [
                "1、单例模式",
                "2、八种写法——饿汉式2种",
                "3、八种写法——懒汉式+多线程Debug",
                "4、八种写法——懒汉式+同步2种",
                "5、八种写法——双重检查",
                "6、八种写法——静态内部类",
                "7、八种写法——枚举",
                "8、单例模式总结",
              ],
            },
            {
              weekname: "第 47 课 Gof 23种设计模式（3）",
              dis: "原型模式，适配器模式",
              children: [
                "1、原型模式",
                "2、深克隆 VS 浅克隆和其他考点",
                "3、原型模式总结",
                "4、适配器模式——类",
                "5、适配器模式——对象",
              ],
            },
            {
              weekname: "第 48 课 Gof 23种设计模式（4）",
              dis: "外观模式，装饰者模式，组合模式，享元模式",
              children: [
                "1、外观模式",
                "2、装饰者模式",
                "3、组合模式",
                "4、享元模式",
              ],
            },
            {
              weekname: "第 49 课 Gof 23种设计模式（5）",
              dis: "桥接模式，代理模式，迭代器模式，解释器模式",
              children: [
                "1、桥接模式",
                "2、代理模式",
                "3、迭代器模式",
                "4、解释器模式",
              ],
            },
            {
              weekname: "第 50 课 Gof 23种设计模式（6）",
              dis: "访问者模式，中介者模式，备忘录模式，观察者模式",
              children: [
                "1、访问者模式",
                "2、中介者模式",
                "3、备忘录模式",
                "4、观察者模式",
              ],
            },
            {
              weekname: "第 51 课 Gof 23种设计模式（7）",
              dis: "命令模式，责任链模式，状态模式，策略模式，模板方法模式",
              children: [
                "1、命令模式",
                "2、责任链模式",
                "3、状态模式",
                "4、策略模式",
                "5、模板方法模式",
              ],
            },
            {
              weekname: "第 52 课 Parking Lot 停车场",
              dis: "Parking Lot 停车场",
              children: [
                "1、OOD万能解法",
                "2、OOD加分项1",
                "3、OOD加分项2",
                "4、Method的表达",
                "5、OOD加分项3",
                "5、面试总结",
              ],
            },
            {
              weekname: "第 53 课 Elevator 电梯",
              dis: "Elevator 电梯",
              children: [
                "1、三步解法1",
                "2、三步万能解法2",
                "3、4种电梯算法",
                "4、三步万能解法3",
              ],
            },
            {
              weekname: "第 54 课 Amazon Locker 亚马逊快递柜",
              dis: "Amazon Locker 亚马逊快递柜",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
                "3、三步万能解法3",
              ],
            },
            {
              weekname: "第 55 课 Vending Machine 自动售货机",
              dis: "Vending Machine 自动售货机",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
              ],
            },
            {
              weekname: "第 56 课 Restaurant Reservation System 餐厅预订系统",
              dis: "Restaurant Reservation System 餐厅预订系统",
              children: [
                "1、预定类讲解1",
                "2、预定类讲解2",
                "3、预定类讲解3",
              ],
            },
            {
              weekname: "第 57 课 Hotel Reservation System 酒店预定系统",
              dis: "Hotel Reservation System 酒店预定系统",
              children: [
                "1、Hotel Reservation",
              ],
            },
            {
              weekname: "第 58 课 Movie Ticket Booking System 电影票订票系统",
              dis: "Movie Ticket Booking System 电影票订票系统",
              children: [
                "1、Movie Ticket 1",
                "2、Movie Ticket 2",
              ],
            },
            {
              weekname: "第 59 课 Tic Tac Toe 井字游戏",
              dis: "Tic Tac Toe 井字游戏",
              children: [
                "1、TicTacToe",
              ],
            },
            {
              weekname: "第 60 课 Chess 国际象棋",
              dis: "Chess 国际象棋",
              children: [
                "1、Chess 1",
                "2、Chess 2",
              ],
            },
            {
              weekname: "第 61 课 Blackjack 21点纸牌游戏",
              dis: "Blackjack 21点纸牌游戏",
              children: [
                "1、Blackjack",
              ],
            },
            {
              weekname: "第 62 课 ATM ATM机",
              dis: "ATM ATM机",
              children: [
                "1、ATM",
              ],
            },
            {
              weekname: "第 63 课  Online Book Reader System 在线图书系统",
              dis: "Online Book Reader System 在线图书系统",
              children: [
                "1、Online Book Reader System",
              ],
            },
          ],
        },
        {
          name: "OOD 实战案例",
          weeks: [
            {
              weekname: "第 52 课 Parking Lot 停车场",
              dis: "Parking Lot 停车场",
              children: [
                "1、OOD万能解法",
                "2、OOD加分项1",
                "3、OOD加分项2",
                "4、Method的表达",
                "5、OOD加分项3",
                "5、面试总结",
              ],
            },
            {
              weekname: "第 53 课 Elevator 电梯",
              dis: "Elevator 电梯",
              children: [
                "1、三步解法1",
                "2、三步万能解法2",
                "3、4种电梯算法",
                "4、三步万能解法3",
              ],
            },
            {
              weekname: "第 54 课 Amazon Locker 亚马逊快递柜",
              dis: "Amazon Locker 亚马逊快递柜",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
                "3、三步万能解法3",
              ],
            },
            {
              weekname: "第 55 课 Vending Machine 自动售货机",
              dis: "Vending Machine 自动售货机",
              children: [
                "1、三步万能解法1",
                "2、三步万能解法2",
              ],
            },
            {
              weekname: "第 56 课 Restaurant Reservation System 餐厅预订系统",
              dis: "Restaurant Reservation System 餐厅预订系统",
              children: [
                "1、预定类讲解1",
                "2、预定类讲解2",
                "3、预定类讲解3",
              ],
            },
            {
              weekname: "第 57 课 Hotel Reservation System 酒店预定系统",
              dis: "Hotel Reservation System 酒店预定系统",
              children: [
                "1、Hotel Reservation",
              ],
            },
            {
              weekname: "第 58 课 Movie Ticket Booking System 电影票订票系统",
              dis: "Movie Ticket Booking System 电影票订票系统",
              children: [
                "1、Movie Ticket 1",
                "2、Movie Ticket 2",
              ],
            },
            {
              weekname: "第 59 课 Tic Tac Toe 井字游戏",
              dis: "Tic Tac Toe 井字游戏",
              children: [
                "1、TicTacToe",
              ],
            },
            {
              weekname: "第 60 课 Chess 国际象棋",
              dis: "Chess 国际象棋",
              children: [
                "1、Chess 1",
                "2、Chess 2",
              ],
            },
            {
              weekname: "第 61 课 Blackjack 21点纸牌游戏",
              dis: "Blackjack 21点纸牌游戏",
              children: [
                "1、Blackjack",
              ],
            },
            {
              weekname: "第 62 课 ATM ATM机",
              dis: "ATM ATM机",
              children: [
                "1、ATM",
              ],
            },
            {
              weekname: "第 63 课  Online Book Reader System 在线图书系统",
              dis: "Online Book Reader System 在线图书系统",
              children: [
                "1、Online Book Reader System",
              ],
            },
          ],
        },
        {
          name: "System Design",
          weeks: [
            {
              weekname: "第 64 课 System Design",
              dis: "System Design",
              children: [
                "System Design，九月初开放目录",
              ],
            },

          ],
        },
      ],
      //求职规划班
      CsJobEducation: [
        {
			
          name: "第一章",
		  name2: "课程介绍",
          weeks: [
            {
              weekname: "来到美国的方式方法，到底有哪些",
              children: [
                "1、课程介绍",
                "2、留美拿绿卡的途径",
                "3、如何来美国工作",
              ],
            },
          ],
        },
        {
          name: "第二章",
		  name2: "北美CS留学",
          weeks: [
            {
              weekname:"手把手教你如何留学选校以及避坑",
              children:[
                "1、本科留学申请",
                "2、研究生留学申请",
                "3、Phd留学申请",
                "4、国内在职留学申请",
                "5、地理位置优先",
                "6、推荐10所留学学校",
                "7、转码CS留学",
                "8、留学机构的坑",
                "9、美国学制",
                "10、留学成本",
                "11、为什么万众转码",
                  ]
            },
          ],
        },

        {
          name: "第三章",
          name2: "北美CS规划",
          weeks: [
            {
              weekname:"本硕博规划、北美找工所有问题一网打尽",
              children:[
                "1、本科求职规划",
                "2、研究生求职规划",
                "3、博士求职规划",
                "4、北美面试内容",
                "5、选Data Science可以吗",
                "6、怎么样才能找到工作",
                "7、北美历年找工作形式",
                "8、如何最快速的刷题",
                "9、为什么公司考刷题",
                "10、刷题语言的选择",
                "11、LeetCode 分类顺序表",
                "12、是否要找培训机构",
                "13、直播，不是一种好的方式",
                "14、CS前景如何",
                "15、国内大学准备北美面试内容",
                "16、国内外面试区别",
              ],
            },
          ],
        },
        {
          name: "第四章",
          name2: "北美CS求职",
          weeks: [
            {
              weekname:"北美大厂求职全流程，公司内幕揭秘",
              children:[
                "1、北美大厂面试的流程",
                "2、如何拿到面试的机会",
                "3、Online Assessment",
                "4、Phone Interview & Virtual On-site",
                "5、On-site",
                "6、心态调整",
                "7、美国实习能赚多少钱",
                "8、美国应届能赚多少钱",
                "9、美国跳槽能赚多少钱",
                "10、ICC是什么",
                "11、北美互联网公司",
                "12、Google公司求职内部揭秘",
                "13、Amazon公司求职内部揭秘",
                "14、Microsoft公司求职内部揭秘",
                "15、Meta公司求职内部揭秘",
              ],
            },
          ],
        },
        {
          name: "第五章",
          name2: "身份转变",
          weeks: [
            {
              weekname: "如何华丽留美？遇到卡点怎么办？",
              children: [
                "1、F1学生签证",
                "2、CPT专业实习",
                "3、OPT专业实习",
                "4、毕业没找到工作怎么办",
                "5、H1b工作签证",
                "6、抽不中H1B怎么办",
                "7、绿卡",
              ],
            },
          ],
        },
        {
          name: "第六章",
          name2: "职场夜话",
          weeks: [
            {
              weekname:"如何华丽留美？遇到卡点怎么办？",
              children: [
                "1、北美晋升",
                "2、北美工作强度",
                "3、北美35危机",
                "4、物价、车价、房价",
                "5、北美怎么找对象",
                "6、回国工作",
                "7、创业艰辛",
              ],
            },
          ],
        },
      ],
      //LC千题300免费
      leetCodeFree300: [
        {
          name: "LC 1 - 50题",
          weeks: [
            {
              weekname: "LeetCode 1 - 50 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "1. Two Sum",
                "2. Add Two Numbers",
                "3. Longest Substring Without Repeating Characters",
                "4. Median of Two Sorted Arrays",
                "5. Longest Palindromic Substring",
                "6. ZigZag Conversion",
                "7. Reverse Integer",
                "8. String to Integer (atoi)",
                "9. Palindrome Number",
                "10. Regular Expression Matching",
                "11. Container With Most Water",
                "12. Integer to Roman",
                "13. Roman to Integer",
                "14. Longest Common Prefix",
                "15. 3Sum",
                "16. 3Sum Closest",
                "17. Letter Combinations of a Phone Number",
                "18. 4Sum",
                "19. Remove Nth Node From End of List",
                "20. Valid Parentheses",
                "21. Merge Two Sorted Lists",
                "22. Generate Parentheses",
                "23. Merge k Sorted Lists",
                "24. Swap Nodes in Pairs",
                "25. Reverse Nodes in k-Group",
                "26. Remove Duplicates from Sorted Array",
                "27. Remove Element",
                "28. Implement strStr()",
                "29. Divide Two Integers",
                "30. Substring with Concatenation of All Words",
                "31. Next Permutation",
                "32. Longest Valid Parentheses",
                "33. Search in Rotated Sorted Array",
                "34. Search for a Range",
                "35. Search Insert Position",
                "36. Valid Sudoku",
                "37. Sudoku Solver",
                "38. Count and Say",
                "39. Combination Sum",
                "40. Combination Sum II",
                "41. First Missing Positive",
                "42. Trapping Rain Water",
                "43. Multiply Strings",
                "44. Wildcard Matching",
                "45. Jump Game II",
                "46. Permutations",
                "47. Permutations II",
                "48. Rotate Image",
                "49. Group Anagrams",
                "50. Pow(x, n)",
              ],
            },
          ],
        },
        {
          name: "LC 51 - 100题",
          weeks: [
            {
              weekname: "LeetCode 51 - 100 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "51. N-Queens",
                "52. N-Queens II",
                "53. Maximum Subarray",
                "54. Spiral Matrix",
                "55. Jump Game",
                "56. Merge Intervals",
                "57. Insert Interval",
                "58. Length of Last Word",
                "59. Spiral Matrix II",
                "60. Permutation Sequence",
                "61. Rotate List",
                "62. Unique Paths",
                "63. Unique Paths II",
                "64. Minimum Path Sum",
                "65. Valid Number",
                "66. Plus One",
                "67. Add Binary",
                "68. Text Justification",
                "69. Sqrt(x)",
                "70. Climbing Stairs",
                "71. Simplify Path",
                "72. Edit Distance",
                "73. Set Matrix Zeroes",
                "74. Search a 2D Matrix",
                "75. Sort Colors",
                "76. Minimum Window Substring",
                "77. Combinations",
                "78. Subsets",
                "79. Word Search",
                "80. Remove Duplicates from Sorted Array II",
                "81. Search in Rotated Sorted Array II",
                "82. Remove Duplicates from Sorted List II",
                "83. Remove Duplicates from Sorted List",
                "84. Largest Rectangle in Histogram",
                "85. Maximal Rectangle",
                "86. Partition List",
                "87. Scramble String",
                "88. Merge Sorted Array",
                "89. Gray Code",
                "90. Subsets II",
                "91. Decode Ways",
                "92. Reverse Linked List II",
                "93. Restore IP Addresses",
                "94. Binary Tree Inorder Traversal",
                "95. Unique Binary Search Trees II",
                "96. Unique Binary Search Trees",
                "97. Interleaving String",
                "98. Validate Binary Search Tree",
                "99. Recover Binary Search Tree",
                "100. Same Tree",
              ],
            },
          ],
        },
        {
          name: "LC 101 -150题",
          weeks: [
            {
              weekname: "LeetCode 101 - 150 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "101. Symmetric Tree",
                "102. Binary Tree Level Order Traversal",
                "103. Binary Tree Zigzag Level Order Traversal",
                "104. Maximum Depth of Binary Tree",
                "105. Construct Binary Tree from Preorder and Inorder Traversal",
                "106. Construct Binary Tree from Inorder and Postorder Traversal",
                "107. Binary Tree Level Order Traversal II",
                "108. Convert Sorted Array to Binary Search Tree",
                "109. Convert Sorted List to Binary Search Tree",
                "110. Balanced Binary Tree",
                "111. Minimum Depth of Binary Tree",
                "112. Path Sum",
                "113. Path Sum II",
                "114. Flatten Binary Tree to Linked List",
                "115. Distinct Subsequences",
                "116. Populating Next Right Pointers in Each Node",
                "117. Populating Next Right Pointers in Each Node II",
                "118. Pascal's Triangle",
                "119. Pascal's Triangle II",
                "120. Triangle",
                "121. Best Time to Buy and Sell Stock",
                "122. Best Time to Buy and Sell Stock II",
                "123. Best Time to Buy and Sell Stock III",
                "124. Binary Tree Maximum Path Sum",
                "125. Valid Palindrome",
                "126. Word Ladder II",
                "127. Word Ladder",
                "128. Longest Consecutive Sequence",
                "129. Sum Root to Leaf Numbers",
                "130. Surrounded Regions",
                "131. Palindrome Partitioning",
                "132. Palindrome Partitioning II",
                "133. Clone Graph",
                "134. Gas Station",
                "135. Candy",
                "136. Single Number",
                "137. Single Number II",
                "138. Copy List with Random Pointer",
                "139. Word Break",
                "140. Word Break II",
                "141. Linked List Cycle",
                "142. Linked List Cycle II",
                "143. Reorder List",
                "144. Binary Tree Preorder Traversal",
                "145. Binary Tree Postorder Traversal",
                "146. LRU Cache",
                "147. Insertion Sort List",
                "148. Sort List",
                "149. Max Points on a Line",
                "150. Evaluate Reverse Polish Notation",
              ],
            },
          ],
        },
        {
          name: "LC 151 - 200 题",
          weeks: [
            {
              weekname: "LeetCode 151 - 200 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "151. Reverse Words in a String",
                "152. Maximum Product Subarray",
                "153. Find Minimum in Rotated Sorted Array",
                "154. Find Minimum in Rotated Sorted Array II",
                "155. Min Stack",
                "156. Binary Tree Upside Down",
                "157. Read N Characters Given Read4",
                "158. Read N Characters Given Read4 II - Call multiple times",
                "159. Longest Substring with At Most Two Distinct Characters",
                "160. Intersection of Two Linked Lists",
                "161. One Edit Distance",
                "162. Find Peak Element",
                "163. Missing Ranges",
                "164. Maximum Gap",
                "165. Compare Version Numbers",
                "166. Fraction to Recurring Decimal",
                "167. Two Sum II - Input array is sorted",
                "168. Excel Sheet Column Title",
                "169. Majority Element",
                "170. Two Sum III - Data structure design",
                "171. Excel Sheet Column Number",
                "172. Factorial Trailing Zeroes",
                "173. Binary Search Tree Iterator",
                "174. Dungeon Game",
                "179. Largest Number",
                "186. Reverse Words in a String II",
                "187. Repeated DNA Sequences",
                "188. Best Time to Buy and Sell Stock IV",
                "189. Rotate Array",
                "190. Reverse Bits",
                "191. Number of 1 Bits",
                "198. House Robber",
                "199. Binary Tree Right Side View",
                "200. Number of Islands",
              ],
            },
          ],
        },
        {
          name: "LC 201 - 250 题",
          weeks: [
            {
              weekname: "LeetCode 201 - 250 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "201. Bitwise AND of Numbers Range",
                "202. Happy Number",
                "203. Remove Linked List Elements",
                "204. Count Primes",
                "205. Isomorphic Strings",
                "206. Reverse Linked List",
                "207. Course Schedule",
                "208. Implement Trie (Prefix Tree)",
                "209. Minimum Size Subarray Sum",
                "210. Course Schedule II",
                "211. Add and Search Word - Data structure design",
                "212. Word Search II",
                "213. House Robber II",
                "214. Shortest Palindrome",
                "215. Kth Largest Element in an Array",
                "216. Combination Sum III",
                "217. Contains Duplicate",
                "218. The Skyline Problem",
                "219. Contains Duplicate II",
                "220. Contains Duplicate III",
                "221. Maximal Square",
                "222. Count Complete Tree Nodes",
                "223. Rectangle Area",
                "224. Basic Calculator",
                "225. Implement Stack using Queues",
                "226. Invert Binary Tree",
                "227. Basic Calculator II",
                "228. Summary Ranges",
                "229. Majority Element II",
                "230. Kth Smallest Element in a BST",
                "231. Power of Two",
                "232. Implement Queue using Stacks",
                "233. Number of Digit One",
                "234. Palindrome Linked List",
                "235. Lowest Common Ancestor of a Binary Search Tree",
                "236. Lowest Common Ancestor of a Binary Tree",
                "237. Delete Node in a Linked List",
                "238. Product of Array Except Self",
                "239. Sliding Window Maximum",
                "240. Search a 2D Matrix II",
                "241. Different Ways to Add Parentheses",
                "242. Valid Anagram",
                "243. Shortest Word Distance",
                "244. Shortest Word Distance II",
                "245. Shortest Word Distance III",
                "246. Strobogrammatic Number",
                "247. Strobogrammatic Number II",
                "248. Strobogrammatic Number III",
                "249. Group Shifted Strings",
                "250. Count Univalue Subtrees",
              ],
            },
          ],
        },
        {
          name: "LC 251 - 300 题",
          weeks: [
            {
              weekname: "LeetCode 251 - 300 题",
              dis:
                  "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
              children: [
                "251. Flatten 2D Vector",
                "252. Meeting Rooms",
                "253. Meeting Rooms II",
                "254. Factor Combinations",
                "255. Verify Preorder Sequence in Binary Search Tree",
                "256. Paint House",
                "257. Binary Tree Paths",
                "258. Add Digits",
                "259. 3Sum Smaller",
                "260. Single Number III",
                "261. Graph Valid Tree",
                "263. Ugly Number",
                "264. Ugly Number II",
                "265. Paint House II",
                "266. Palindrome Permutation",
                "267. Palindrome Permutation II",
                "268. Missing Number",
                "269. Alien Dictionary",
                "270. Closest Binary Search Tree Value",
                "271. Encode and Decode Strings",
                "272. Closest Binary Search Tree Value II",
                "273. Integer to English Words",
                "274. H-Index",
                "275. H-Index II",
                "276. Paint Fence",
                "277. Find the Celebrity",
                "278. First Bad Version",
                "279. Perfect Squares",
                "280. Wiggle Sort",
                "281. Zigzag Iterator",
                "282. Expression Add Operators",
                "283. Move Zeroes",
                "284. Peeking Iterator",
                "285. Inorder Successor in BST",
                "286. Walls and Gates",
                "287. Find the Duplicate Number",
                "288. Unique Word Abbreviation",
                "289. Game of Life",
                "290. Word Pattern",
                "291. Word Pattern II",
                "292. Nim Game",
                "293. Flip Game",
                "294. Flip Game II",
                "295. Find Median from Data Stream",
                "296. Best Meeting Point",
                "297. Serialize and Deserialize Binary Tree",
                "298. Binary Tree Longest Consecutive Sequence",
                "299. Bulls and Cows",
                "300. Longest Increasing Subsequence",
              ],
            },
          ],
        },
      ],
      //Project课程
      usVIPProject: [
        {
          name: "项目速成班",
          weeks: [
            {
              weekname: "第一章 课程介绍",
              children: [
                "1-1 课程介绍",
              ],
            },
            {
              weekname: "第二章 项目整体框架基础",
              children: [
                "2-1 项目整体框架基础",
                "2-2 前端基础",
                "2-3 后端基础",
                "2-4 数据库基础",
              ],
            },
            {
              weekname: "第三章 数据库介绍和设计",
              children: [
                "3-1 安装数据库",
                "3-2 关系型数据库",
                "3-3 数据库设计规范",
                "3-4 数据库设计",
                "3-5 用户登陆表 & 用户信息表",
                "3-6 课程表 & 课程章节表",
                "3-7 评论表 & 用户课程表",
                "3-8 MySQL 操作",
              ],
            },
            {
              weekname: "第四章 前端页面技术介绍",
              children: [
                "4-1 HTML",
                "4-2 HTML5",
                "4-3 CSS",
                "4-4 JavaScript",
                "4-5 JS框架和JQuery",
                "4-6 Bootstrap",
              ],
            },
            {
              weekname: "第五章 后端技术基础",
              children: [
                "5-1 XML和Maven",
                "5-2 Spring MVC",
                "5-3 Tomcat & Jetty",
                "5-4 创建Spring boot项目",
                "5-5 Mybatis & 注解",
              ],
            },
            {
              weekname: "第六章 Spring boot 框架搭建与技巧",
              children: [
                "6-1 项目搭建启动",
                "6-2 Spring boot框架流程",
                "6-3 freemarker",
                "6-4 freemarker显示主页",
                "6-5 多Module拆分原理",
                "6-6 多Module 拆分pom",
                "6-7 多Module拆分代码",
                "6-8 错误页面",
              ],
            },
            {
              weekname: "第七章 用户登录注册个人页面开发",
              children: [
                "7-1 用户登录注册个人页面开发",
                "7-2 工具类实现",
                "7-3 Service和Dao层实现",
                "7-4 Controller开发",
                "7-5 用户名邮箱重复",
                "7-6 登录登出实现",
                "7-7 技术总结补充",
                "7-8 更新用户表",
                "7-9 更新用户数据",
              ],
            },
            {
              weekname: "第八章 课程评论模块开发",
              children: [
                "8-1 课程和用户课程",
                "8-2 拦截器1",
                "8-3 拦截器2",
                "8-4 我的课程页面",
                "8-5 课程章节逻辑",
                "8-6 课程章节实现",
                "8-7 评论基础实现",
                "8-8 评论功能实现",

              ],
            },
            {
              weekname: "第九章 项目简历书写",
              children: [
                "9-1 项目简历书写",
              ],
            },

          ],
        },
      ],


      //北美SDE算法突击班
      // exerciseClass: [
      //   {
      //     name: "第 1 课 ~ 第 3 课",
      //     weeks: [
      //       // {
      //       //   weekname: "第 1 课 位运算",
      //       //   dis: "位运算的考点和扩展延伸",
      //       //   children: [
      //       //     "1、位运算-二进制",
      //       //     "2、位运算-Java 八大基本类型",
      //       //     "3、位运算-位运算",
      //       //     "4、位运算-编码标准",
      //       //     "5、位运算-面试技巧1 - XOR",
      //       //     "6、位运算-面试技巧2 - n&(n - 1)",
      //       //     "7、位运算-面试技巧3 - n&1",
      //       //     "8、位运算-数据量过大怎么办",
      //       //     "9、位运算-BitMap 应用",
      //       //     "10、位运算-Leetcode作业题和思维导图",
      //       //   ],
      //       // },
      //       {
      //         weekname: "第 1 课 数学题",
      //         dis: "数学题考点讲解",
      //         children: [
      //           "1、数学题-数学题概括",
      //           "2、数学题-考察要点1 - 越界",
      //           "3、数学题-考察要点2 - 进位",
      //           "4、数学题-考察要点3 - 符号字母",
      //           "5、数学题-常考题型 - 开方（牛顿法）",
      //           "6、数学题-特殊题型和必背代码",
      //           // "7、数学题-Leetcode作业题和思维导图",
      //         ],
      //       },
      //       {
      //         weekname: "第 2 课 数组题",
      //         dis: "数组常见题型和补充算法",
      //         children: [
      //           "1、数组题-常考题型 1 - 排序算法补充",
      //           "2、数组题-常考题型 2 - 单向双指针",
      //           "3、数组题-扫描线算法",
      //           "4、数组题-子数组 - 进行中处理",
      //           "5、数组题-子数组 - 分段式处理",
      //           "6、数组题-双指针 - 双向双指针",
      //           "7、数组题-常考题型 3 - 二维数组",
      //           "8、数组题-二维矩阵 - 旋转变换",
      //           "9、数组题-常考题型4 - 实现题",
      //           "10、数组题-数学定理",
      //           "11、数组题-Leetcode作业题和思维导图",
      //         ],
      //       },
      //       {
      //         weekname: "第 3 课 二分查找",
      //         dis: "二分查找三种写法区别",
      //         children: [
      //           "1、二分查找-二分查找",
      //           "2、二分查找-递归写法",
      //           "3、二分查找-迭代写法 1：left <= right",
      //           "4、二分查找-迭代写法 2：left < right",
      //           "5、二分查找-迭代写法 3：left + 1< right",
      //           "6、二分查找-Java 源码中的二分查找",
      //           "7、二分查找-三种方法应用",
      //           "8、二分查找-Rotate题型",
      //           "9、二分查找-Rotate题型 follow up",
      //           "10、二分查找-二维数组",
      //           // "11、二分查找-Leetcode作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第 4 课 ~ 第 6 课",
      //     weeks: [
      //       {
      //         weekname: "第 4 课 链表",
      //         dis: "链表常考题型",
      //         children: [
      //           "1、链表-链表题型介绍",
      //           "2、链表-常考题型 1 - 基本操作",
      //           "3、链表-常考题型 2 - 反转",
      //           "4、链表-常考题型 3 - 环",
      //           "5、链表-常考题型 4 - 删除",
      //           "6、链表-链表的递归实现",
      //           "7、链表-综合练习",
      //           // "8、链表-Leetcode作业题和思维导图",
      //         ],
      //       },
      //       // {
      //       //   weekname: "第 6 课 随机",
      //       //   dis: "等概率抽取法和蓄水池算法",
      //       //   children: [
      //       //     "1、随机-等概率抽取法",
      //       //     "2、随机-Leetcode 中的等概率抽取法",
      //       //     "3、随机-蓄水池抽样1 - 选取一个",
      //       //     "4、随机-蓄水池抽样2 - 选取多个",
      //       //     "5、随机-蓄水池算法3 - 代码实现",
      //       //     "6、随机-Leetcode作业题和思维导图",
      //       //   ],
      //       // },
      //       {
      //         weekname: "第 5 课 栈",
      //         dis: "栈常考题型分类划分",
      //         children: [
      //           "1、栈-常考题型1 - 平衡符号",
      //           "2、栈-常考题型2 - 压栈匹配1",
      //           "3、栈-常考题型2 - 压栈匹配2",
      //           "4、栈-常考题型3 - 表达式计算1",
      //           "5、栈-常考题型3 - 表达式计算2",
      //           "6、栈-常考题型4 - 迭代极值1",
      //           "7、栈-常考题型4 - 迭代极值2",
      //           "8、栈-Leetcode 400题作业，思维导图",
      //         ],
      //       },
      //       {
      //         weekname: "第 6 课 字符串",
      //         dis: "字符串常考题型分类划分",
      //         children: [
      //           "1、字符串-String 基本函数",
      //           "2、字符串-字符串函数时间复杂度",
      //           "3、字符串-正则表达式",
      //           "4、字符串-转义字符 & 通配符",
      //           "5、字符串-String VS StringBuilder VS StringBuffer",
      //           "6、字符串-常考题型1 - Anagram",
      //           "7、字符串-常考题型2 - Substring",
      //           "8、字符串-常考题型3 - Parentheses",
      //           "9、字符串-常考题型4 - Palindrome",
      //           "10、字符串-常考题型5 - Subsequence",
      //           "11、字符串-常考题型6 -实现题",
      //           // "12、字符串-Leetcode 400题作业，思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第 7 课 ~ 第 9 课",
      //     weeks: [
      //       // {
      //       //   weekname: "第 9 课 图形",
      //       //   dis: "点，线，面，到各种图形考点",
      //       //   children: [
      //       //     "1、图形-图形题简介",
      //       //     "2、图形-点在线段上",
      //       //     "3、图形-三点方向",
      //       //     "4、图形-点在直线上",
      //       //     "5、图形-点与面的关系",
      //       //     "6、图形-线段是否相交",
      //       //     "7、图形-点是否在多边形内",
      //       //     "8、图形-圆简介",
      //       //     "9、图形-方格与圆",
      //       //     "10、图形-圆与三角形",
      //       //     "11、图形-多边形考点",
      //       //     "12、图形-二分图",
      //       //     "13、图形-正方形",
      //       //     "14、图形-长方形",
      //       //     "15、图形-不平行于x轴怎么办",
      //       //     "16、图形-三角形和小结",
      //       //   ],
      //       // },
      //       {
      //         weekname: "第 7 课 堆",
      //         dis: "PriorityQueue 的各种用法",
      //         children: [
      //           "1、堆-PriorityQueue 写法介绍",
      //           "2、堆-全排序 VS 局部排序",
      //           "3、堆-Arrays.sort 应用",
      //           "4、堆-Pair 和时间复杂度",
      //           "5、堆-类的应用——外排序",
      //           // "6、堆-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //       {
      //         weekname: "第 8 课 回溯法",
      //         dis: "回溯三大原始题型和实现题",
      //         children: [
      //           "1、回溯法-回溯概念",
      //           "2、回溯法-回溯三大题型——排列",
      //           "3、回溯法-回溯三大题型——子集",
      //           "4、回溯法-回溯三大题型——组合",
      //           "5、回溯法-模版和值传递",
      //           "6、回溯法-回溯法的实现题",
      //           // "7、回溯法-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //       {
      //         weekname: "第 9 课 树",
      //         dis: "树的遍历，常考题型",
      //         children: [
      //           "1、树-树的第一想法",
      //           "2、树-双 Pre 题目和解法",
      //           "3、树-Inorder 题目和解法",
      //           "4、树-Postorder 题目和解法",
      //           "5、树-Levelorder 题目和解法",
      //           "6、树-Preorder 和 Backtracking",
      //           "7、树-二叉搜索树",
      //           // "8、树-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第 10 课 ~ 第 12 课",
      //     weeks: [
      //       // {
      //       //   weekname: "第 12 课 图",
      //       //   dis: "图的Flood Fill，BFS，匈牙利算法",
      //       //   children: [
      //       //     "1、图-图的基础回顾",
      //       //     "2、图-Flood Fill 的 DFS",
      //       //     "3、图-Flood Fill 的 BFS",
      //       //     "4、图-BFS 最终优化",
      //       //     "5、图-迷宫，棋盘怎么破",
      //       //     "6、图-双向广度搜索",
      //       //     "7、图-思考方式——转换图",
      //       //     "8、图-匹配",
      //       //     "9、图-匈牙利算法原理",
      //       //     "10、图-匈牙利算法实现",
      //       //     "11、图-Leetcode 作业题和思维导图",
      //       //   ],
      //       // },
      //       {
      //         weekname: "第 10 课 字典树",
      //         dis: "Trie的知识和考点",
      //         children: [
      //           "1、字典树-Trie 介绍",
      //           "2、字典树-TrieNode",
      //           "3、字典树-add() 和 contains() 函数",
      //           "4、字典树-前缀树——startsWith() 函数",
      //           "5、字典树-字符匹配——search() 函数",
      //           "6、字典树-字典结合",
      //           // "7、字典树-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //       {
      //         weekname: "第 11 课 贪心算法",
      //         dis: "贪心的原理和应用",
      //         children: [
      //           "1、贪心算法-贪心算法——贪心思想",
      //           "2、贪心算法-贪心算法解法步骤",
      //           "3、贪心算法-贪心的局限性",
      //           // "4、贪心算法-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //       {
      //         weekname: "第 12 课 设计",
      //         dis: "设计题技巧和总结",
      //         children: [
      //           "1、设计-设计介绍——10种数据结构",
      //           "2、设计-HashSet VS HashMap",
      //           "3、设计-ArrayList VS LinkedList",
      //           "4、设计-Stack VS Queue VS Deque",
      //           "5、设计-TreeSet VS TreeMap VS PriorityQueue",
      //           "6、设计-设置 Class",
      //           "7、设计-做题思想和做题流程总结",
      //           // "8、设计-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "LeetCode 高频",
      //     weeks: [
      //       {
      //         weekname: "LeetCode 高频精选200题",
      //         dis: "LeetCode 题目逐一讲解；Java、Python、C++三种语言答案；思路分析，面试点评一应俱全；时间复杂度，空间复杂度分析到位；外加独家分类顺序表，一天轻松10道题。",
      //         children: [
      //           "LeetCode 高频精选200题，一个月刷完200题速成去面试"
      //         ],
      //       },
      //     ],
      //   },
      //   // {
      //   //   name: "第 13 课 ~ 第 15 课",
      //   //   weeks: [
      //   //     {
      //   //       weekname: "第 15 课 线段树和树状数组",
      //   //       dis: "线段树的实现方式，树状数组的原理及应用",
      //   //       children: [
      //   //         "1、线段树和树状数组-Segment Tree 和 Binary Index Tree",
      //   //         "2、线段树和树状数组-线段树原理",
      //   //         "3、线段树和树状数组-SegmentTreeNode 实现 Segment Tree",
      //   //         "4、线段树和树状数组-数组实现 Segment Tree",
      //   //         "5、线段树和树状数组-lazy 思想",
      //   //         "6、线段树和树状数组-Binary Index Tree 原理",
      //   //         "7、线段树和树状数组-实现 Binary Index Tree",
      //   //         "8、线段树和树状数组-BIT 和 ST 对比",
      //   //         "9、线段树和树状数组-Leetcode 作业题和思维导图",
      //   //       ],
      //   //     },
      //   //   ],
      //   // },
      //   // {
      //   //   name: "第 16 课 ~ 第 18 课",
      //   //   weeks: [
      //   //     {
      //   //       weekname: "第 18 课 动态规划",
      //   //       dis: "如何一步步推导出递归，最难题型",
      //   //       children: [
      //   //         "1、动态规划-如何推到出动态规划",
      //   //         "2、动态规划-Backtracking - Memorization - DP（1）",
      //   //         "3、动态规划-Backtracking - Memorization - DP（2）",
      //   //         "4、动态规划-三种方法小结",
      //   //         "5、动态规划-状态和状态转移方程",
      //   //         "6、动态规划-动态规划做题思路",
      //   //         "7、动态规划-01背包问题",
      //   //         "8、动态规划-01背包问题分析及其优化",
      //   //         "9、动态规划-完全背包——两种解决方法",
      //   //         "10、动态规划-多重背包和混合背包",
      //   //         "11、动态规划-字符串问题",
      //   //         "12、动态规划-网格问题",
      //   //         "13、动态规划-匹配问题",
      //   //         "14、动态规划-follow up —— 打印中间过程",
      //   //         "15、动态规划-贪心算法和动态规划",
      //   //         "16、动态规划-Leetcode 作业题和思维导图",
      //   //       ],
      //   //     },
      //   //   ],
      //   // },
      // ],
      // usExerciseClass: [
      //   {
      //     name: "第一周",
      //     weeks: [
      //       {
      //         weekname: "位运算 ，数学题",
      //         dis: "",
      //         children: [
      //           "1、位运算-二进制",
      //           "2、位运算-Java 八大基本类型",
      //           "3、位运算-位运算",
      //           "4、位运算-编码标准",
      //           "5、位运算-面试技巧1 - XOR",
      //           "6、位运算-面试技巧2 - n&(n - 1)",
      //           "7、位运算-面试技巧3 - n&1",
      //           "8、位运算-数据量过大怎么办",
      //           "9、位运算-BitMap 应用",
      //           "10、位运算-Leetcode作业题和思维导图",
      //           "11、数学题-数学题概括",
      //           "12、数学题-考察要点1 - 越界",
      //           "13、数学题-考察要点2 - 进位",
      //           "14、数学题-考察要点3 - 符号字母",
      //           "15、数学题-常考题型 - 开方（牛顿法）",
      //           "16、数学题-特殊题型和必背代码",
      //           "17、数学题-Leetcode作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第二周",
      //     weeks: [
      //       {
      //         weekname: "数组",
      //         dis: "",
      //         children: [
      //           "1、数组题-常考题型 1 - 排序算法补充",
      //           "2、数组题-常考题型 2 - 单向双指针",
      //           "3、数组题-扫描线算法",
      //           "4、数组题-子数组 - 进行中处理",
      //           "5、数组题-子数组 - 分段式处理",
      //           "6、数组题-双指针 - 双向双指针",
      //           "7、数组题-常考题型 3 - 二维数组",
      //           "8、数组题-二维矩阵 - 旋转变换",
      //           "9、数组题-常考题型4 - 实现题",
      //           "10、数组题-数学定理",
      //           "11、数组题-Leetcode作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第三周",
      //     weeks: [
      //       {
      //         weekname: "二分查找 ，链表，随机",
      //         dis: "",
      //         children: [
      //           "1、二分查找-二分查找",
      //           "2、二分查找-递归写法",
      //           "3、二分查找-迭代写法 1：left <= right",
      //           "4、二分查找-迭代写法 2：left < right",
      //           "5、二分查找-迭代写法 3：left + 1< right",
      //           "6、二分查找-Java 源码中的二分查找",
      //           "7、二分查找-三种方法应用",
      //           "8、二分查找-Rotate题型",
      //           "9、二分查找-Rotate题型 follow up",
      //           "10、二分查找-二维数组",
      //           "11、二分查找-Leetcode作业题和思维导图",
      //           "12、链表-链表题型介绍",
      //           "13、链表-常考题型 1 - 基本操作",
      //           "14、链表-常考题型 2 - 反转",
      //           "15、链表-常考题型 3 - 环",
      //           "16、链表-常考题型 4 - 删除",
      //           "17、链表-链表的递归实现",
      //           "18、链表-综合练习",
      //           "19、链表-Leetcode作业题和思维导图",
      //           "20、随机-等概率抽取法",
      //           "21、随机-Leetcode 中的等概率抽取法",
      //           "22、随机-蓄水池抽样1 - 选取一个",
      //           "23、随机-蓄水池抽样2 - 选取多个",
      //           "24、随机-蓄水池算法3 - 代码实现",
      //           "25、随机-Leetcode作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第四周",
      //     weeks: [
      //       {
      //         weekname: "栈 ，字符串",
      //         dis: "",
      //         children: [
      //           "1、栈-常考题型1 - 平衡符号",
      //           "2、栈-常考题型2 - 压栈匹配1",
      //           "3、栈-常考题型2 - 压栈匹配2",
      //           "4、栈-常考题型3 - 表达式计算1",
      //           "5、栈-常考题型3 - 表达式计算2",
      //           "6、栈-常考题型4 - 迭代极值1",
      //           "7、栈-常考题型4 - 迭代极值2",
      //           "8、栈-Leetcode 400题作业，思维导图",
      //           "9、字符串-String 基本函数",
      //           "10、字符串-字符串函数时间复杂度",
      //           "11、字符串-正则表达式",
      //           "12、字符串-转义字符 & 通配符",
      //           "13、字符串-String VS StringBuilder VS StringBuffer",
      //           "14、字符串-常考题型1 - Anagram",
      //           "15、字符串-常考题型2 - Substring",
      //           "16、字符串-常考题型3 - Parentheses",
      //           "17、字符串-常考题型4 - Palindrome",
      //           "18、字符串-常考题型5 - Subsequence",
      //           "19、字符串-常考题型6 -实现题",
      //           "20、字符串-Leetcode 400题作业，思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第五周",
      //     weeks: [
      //       {
      //         weekname: "复习总结 & 面试技巧指导",
      //         dis: "",
      //         children: [
      //           "给大家时间进行复习，查缺补漏，并且教怎么在面试中，如何和面试官交流去写代码",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第六周",
      //     weeks: [
      //       {
      //         weekname: "图形 ，堆",
      //         dis: "",
      //         children: [
      //           "1、图形-图形题简介",
      //           "2、图形-点在线段上",
      //           "3、图形-三点方向",
      //           "4、图形-点在直线上",
      //           "5、图形-点与面的关系",
      //           "6、图形-线段是否相交",
      //           "7、图形-点是否在多边形内",
      //           "8、图形-圆简介",
      //           "9、图形-方格与圆",
      //           "10、图形-圆与三角形",
      //           "11、图形-多边形考点",
      //           "12、图形-二分图",
      //           "13、图形-正方形",
      //           "14、图形-长方形",
      //           "15、图形-不平行于x轴怎么办",
      //           "16、图形-三角形和小结",
      //           "17、堆-PriorityQueue 写法介绍",
      //           "18、堆-全排序 VS 局部排序",
      //           "19、堆-Arrays.sort 应用",
      //           "20、堆-Pair 和时间复杂度",
      //           "21、堆-类的应用——外排序",
      //           "22、堆-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第七周",
      //     weeks: [
      //       {
      //         weekname: "回溯法 ，图",
      //         dis: "",
      //         children: [
      //           "1、回溯法-回溯概念",
      //           "2、回溯法-回溯三大题型——排列",
      //           "3、回溯法-回溯三大题型——子集",
      //           "4、回溯法-回溯三大题型——组合",
      //           "5、回溯法-模版和值传递",
      //           "6、回溯法-回溯法的实现题",
      //           "7、回溯法-Leetcode 作业题和思维导图",
      //           "8、图-图的基础回顾",
      //           "9、图-Flood Fill 的 DFS",
      //           "10、图-Flood Fill 的 BFS",
      //           "11、图-BFS 最终优化",
      //           "12、图-迷宫，棋盘怎么破",
      //           "13、图-双向广度搜索",
      //           "14、图-思考方式——转换图",
      //           "15、图-匹配",
      //           "16、图-匈牙利算法原理",
      //           "17、图-匈牙利算法实现",
      //           "18、图-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第八周",
      //     weeks: [
      //       {
      //         weekname: "树 ，字典树 ，线段树 ，树状数组",
      //         dis: "",
      //         children: [
      //           "1、树-树的第一想法",
      //           "2、树-双 Pre 题目和解法",
      //           "3、树-Inorder 题目和解法",
      //           "4、树-Postorder 题目和解法",
      //           "5、树-Levelorder 题目和解法",
      //           "6、树-Preorder 和 Backtracking",
      //           "7、树-二叉搜索树",
      //           "8、树-Leetcode 作业题和思维导图",
      //           "9、字典树-Trie 介绍",
      //           "10、字典树-TrieNode",
      //           "11、字典树-add() 和 contains() 函数",
      //           "12、字典树-前缀树——startsWith() 函数",
      //           "13、字典树-字符匹配——search() 函数",
      //           "14、字典树-字典结合",
      //           "15、字典树-Leetcode 作业题和思维导图",
      //           "16、线段树和树状数组-Segment Tree 和 Binary Index Tree",
      //           "17、线段树和树状数组-线段树原理",
      //           "18、线段树和树状数组-SegmentTreeNode 实现 Segment Tree",
      //           "19、线段树和树状数组-数组实现 Segment Tree",
      //           "20、线段树和树状数组-lazy 思想",
      //           "21、线段树和树状数组-Binary Index Tree 原理",
      //           "22、线段树和树状数组-实现 Binary Index Tree",
      //           "23、线段树和树状数组-BIT 和 ST 对比",
      //           "24、线段树和树状数组-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第九周",
      //     weeks: [
      //       {
      //         weekname: "贪心 ，设计 ，动态规划",
      //         dis: "",
      //         children: [
      //           "1、贪心算法-贪心算法——贪心思想",
      //           "2、贪心算法-贪心算法解法步骤",
      //           "3、贪心算法-贪心的局限性",
      //           "4、贪心算法-Leetcode 作业题和思维导图",
      //           "5、设计-设计介绍——10种数据结构",
      //           "6、设计-HashSet VS HashMap",
      //           "7、设计-ArrayList VS LinkedList",
      //           "8、设计-Stack VS Queue VS Deque",
      //           "9、设计-TreeSet VS TreeMap VS PriorityQueue",
      //           "10、设计-设置 Class",
      //           "11、设计-做题思想和做题流程总结",
      //           "12、设计-Leetcode 作业题和思维导图",
      //           "13、动态规划-如何推到出动态规划",
      //           "14、动态规划-Backtracking - Memorization - DP（1）",
      //           "15、动态规划-Backtracking - Memorization - DP（2）",
      //           "16、动态规划-三种方法小结",
      //           "17、动态规划-状态和状态转移方程",
      //           "18、动态规划-动态规划做题思路",
      //           "19、动态规划-01背包问题",
      //           "20、动态规划-01背包问题分析及其优化",
      //           "21、动态规划-完全背包——两种解决方法",
      //           "22、动态规划-多重背包和混合背包",
      //           "23、动态规划-字符串问题",
      //           "24、动态规划-网格问题",
      //           "25、动态规划-匹配问题",
      //           "26、动态规划-follow up —— 打印中间过程",
      //           "27、动态规划-贪心算法和动态规划",
      //           "28、动态规划-Leetcode 作业题和思维导图",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "第十周",
      //     weeks: [
      //       {
      //         weekname: "复习总结 & 简历修改指导",
      //         dis: "",
      //         children: [
      //           "总结回顾所有课程体系，并且针对个人简历，如何修改英文简历，HR看重的点有哪些，如何写好简历",
      //         ],
      //       },
      //     ],
      //   },
      // ],
      //北美SDE求职旗舰班
    };
  },
};
</script>
<style></style>
