<template>
  <div class="ood">
    <div class="container">
      <img src="@/assets/img/course/Class11/banner.jpg" alt="" />
	  <div class="tabs">
		<div
		  v-for="(tab, index) in tabs"
		  :key="index"
		  class="tab-item"
		  :class="{ active: activeTab === index }"
		  @click="selectTab(index)"
		>
		  {{ tab.title }}
		</div>
	  </div>
	  <template v-if="activeTab == 0">
		  <img src="@/assets/img/course/Class11/1.jpg" alt="" />
		  <img src="@/assets/img/course/Class11/2.jpg" alt="" />
		  <img src="@/assets/img/course/Class11/3.jpg" alt="" />
		  <img src="@/assets/img/course/Class11/4.jpg" alt="" />
		  <img src="@/assets/img/course/Class11/5.jpg" alt="" />
		  <img src="@/assets/img/course/Class11/6.jpg" alt="" />
		  <img src="@/assets/img/course/Class11/7.jpg" alt="" />
	  </template>
	  <template v-if="activeTab == 1">
		  <course-detail course-i-d="11" />
	  </template>
    </div>
    <div class="prices">
      <span class="usdPrice">￥{{ courseCombosList[0].price }}</span>
      <span class="rmbPrice"></span>
      <div class="buy" @click="buyNow(courseCombosList[0])">立即购买</div>
    </div>
  </div>
</template>
<script>

import { Toast } from "vant";
import { getCourseDetail } from "@/service/course";
import { setStorageForArray } from "@/utils/index";
import courseDetail from "@/components/courseDetail";
export default {
  name: "Class11",
  components: {
    courseDetail,
  },
  data() {
    return {
		activeTab: 0,
		tabs:[
			{
				title:'课程介绍'
			},{
				title:'班课大纲'
			}
		],
      courseCombosList: [],
    };
  },
  mounted() {
    getCourseDetail(11).then((res) => {
      this.courseCombosList = res.result.courseCombosList;
    });
  },
  methods: {
	  selectTab(selectedIndex) {
		this.activeTab = selectedIndex;
	  },
    buyNow(item) {
      setStorageForArray("order", [item]);
      this.$router.push("/pay/confirm");
    },
  },
};
</script>
<style scoped lang="scss">
@import url("../../assets/css/course.scss");
.ood {
  width: 100%;
  padding-top: 54px;
  .container {
    img {
      width: 100%;
      vertical-align: top;
    }
  }
}
.tabs{
	position: relative;
	z-index: 99;
	display: flex;
	align-items: center;
	height: 50px;
	background: #FFFFFF;
	box-shadow: 0px 3px 6px 0px rgba(123,123,123,0.17);
	.tab-item{
		position: relative;
		text-align: center;
		width: 50%;
		height: 50px;
		line-height: 50px;
	}
	.active{
		color: #FA6400;
	}
	.active::before{
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		bottom: 0;
		background: #FA6400;
	}
	
}
</style>