<template>
  <div>
    <div
      class="curson-con course-catalog"
    >
      <div class="chapter-content">
        <!-- 小标签 -->
        <div
          v-for="(item, index) in steps"
          :id="'step' + index"
          :key="index"
          :ref="`step${index}`"
          class="step-box"
        >
          <div class="step-name">{{ item.name }}</div>
          <div v-for="(week, jj) in item.weeks" :key="jj" class="week-box">
			  <div class="step-title">{{ item.name2 }}</div>
            <div class="week-name">
              {{ week.weekname }}
            </div>
            <div v-if="week.children" class="detial">
              <ul class="step-list" v-if="showList[index]">
                <li v-for="(child, ii) in week.children" :key="ii">
                  {{ child }} 
                </li>
              </ul>
            </div>
			<div class="step-toggle" @click="toggleList(index)" :class="showList[index]? 'show' : 'hide'">
			  <span>{{ showList[index]? '收起列表' : '展开列表' }}</span><span class="el-icon-arrow-down"></span>
			</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import courseDatas from "../utils/courseDatas";

export default {
  name: "courseDetail",
  props: {
    TabActive: 0,
    courseID: "",
  },
  mixins: [courseDatas],
  data() {
    return {
      steps: [],
	  showList: [],
    };
  },
  mounted() {
	  this.setCourseContent();
	  this.showList = this.steps.map(() => false); // 默认为所有子列表都隐藏  
  },
  created() {},
  watch: {},
  methods: {
	toggleList(index) {
	      console.log('Before toggle:', this.showList[index]);
	      this.$set(this.showList, index,!this.showList[index]);
	      console.log('After toggle:', this.showList[index]);
	    },
    setCourseContent() {
      console.log(this.courseID, this.exerciseClass);
      switch (this.courseID) {
        case "1": //leetcode100
          // this.steps = this.leetcode100;
          break;
        case "2": //LeetCode千题讲解视频
          this.steps = this.leetCodeVideoClass;
          break;
        case "3": //北美SDE算法速成班
          this.steps = this.crashClass;
          break;
        case "4": //求职面试算法班
          this.steps = this.algorithmFoundationClass;
          break;
        case "5":
          this.steps = this.algorithmExerciseClass;
          break;
        case "9": //求职面试刷题班
          this.steps = this.exerciseClass;
          break;
        case "6": //北美速成
          this.steps = this.usExerciseClass;
          break;
        case "7": // 旗舰班
          this.steps = this.VIPClass;
          break;
        case "8": // 面向对象设计班
          this.steps = this.OODDesignPattern;
          break;
        case "11": // 北美CS留学求职规划班
          this.steps = this.CsJobEducation;
          break;
        case "12": // 项目班
          this.steps = this.usVIPProject;
          break;
        case "13": // LC免费300题
          this.steps = this.leetCodeFree300;
			break;
      }
    },
  },
};
</script>

<style scoped>
	.chapter-content{
		padding: 20px;
		font-size: 14px;
	}
	.step-box{
		display: flex;
		margin-bottom: 20px;
	}
	.week-box{
		flex: 1;
	}
	.step-name{
		background: url(../assets/img/course/Class11/title.png) no-repeat;
		width: 112px;
		height: 26px;
		line-height: 26px;
		color: #fff;
		text-align: center;
		background-size: contain;
		margin-right: 10px;
	}
	.step-title{
		font-weight: bold;
		font-size: 16px;
	}
	.week-name{
		margin-bottom: 10px;
	}
	.step-list{
		position: relative;
		padding-left: 13px;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-left: 6px;
		margin-bottom: 15px;
		border-left: 1px solid #cdcdcd;
	}
	.step-list::before{
		width: 14px;
		height: 14px;
		background-color: #cdcdcd;
		content: '';
		position: absolute;
		left: -8px;
		top: -4px;
		border-radius: 12px;
	}
	.step-list::after{
	    width: 0;
	    height: 0;
	    border-left: 6px solid transparent;
	    border-right: 6px solid transparent;
	    border-top: 12px solid #cdcdcd;
	    content: '';
	    position: absolute;
	    left: -6px;
	    bottom: -4px;
	}
	.step-list li{
		position: relative;
		margin-bottom: 10px;
		color: #666666;
		word-break: break-all;
	}
	.step-list li:last-child{
		margin-bottom: 0;
	}
	.step-list li::before{
		width: 8px;
		height: 8px;
		background-color: #ffffff;
		border: 2px solid #fa6b0f;
		content: '';
		position: absolute;
		left: -20px;
		top: 3px;
		border-radius: 8px;
	}
	.step-toggle{
		display: flex;
		align-items: center;
		color: #0075f3;
		.el-icon-arrow-down{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 12px;
			height: 12px;
			border: 1px solid #0075f3;
			border-radius: 12px;
			margin-left: 5px;
			font-size: 12px;
		}
	}
	.step-toggle.show .el-icon-arrow-down{
		transform: rotate(180deg);
	}
	
	
	
	
</style>
